import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.tsx";
import { AppSettingsProvider } from "./contexts/app-settings/index.tsx";
import reportWebVitals from "./reportWebVitals";
import { getFirebase } from "./lib/firebase.ts";
// import * as Sentry from "@sentry/react";
// import { CaptureConsole } from '@sentry/integrations';

// Sentry.init({
// 	dsn: "https://3929ff839c73c9624ffc272dad74f976@o4506910568415232.ingest.us.sentry.io/4506910574575616",
// 	environment: process.env.NODE_ENV,
// 	attachStacktrace: true,
// 	integrations:
// 	[
// 		process.env.NODE_ENV === "development" && new Sentry.Integrations.Breadcrumbs({ console: false }),
// 		// new Sentry.Integrations.Breadcrumbs({ console: false }),
// 		// process.env.NODE_ENV === "development" && Sentry.breadcrumbsIntegration({ console: false }),
// 		// new CaptureConsole({
// 		// 	levels: ['error']
// 		// }),
// 		// Sentry.captureConsoleIntegration({ levels: ['error'] }),
// 		Sentry.browserTracingIntegration(),
// 		Sentry.replayIntegration({
// 			maskAllText: false,
// 			// mask: false,
// 			blockAllMedia: false,
// 			// useCompression: false,
// 		}),
//   ],
// 	// Performance Monitoring
// 	tracesSampleRate: 1.0, //  Capture 100% of the transactions
// 	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
// 	tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
// 	// Session Replay (default is 0.2)
// 	replaysSessionSampleRate: process.env.NODE_ENV === "development" ? 0.0 : 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
// 	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// 	// tunnel: "http://127.0.0.1:5001/redistricter/us-central1/tunnel",
// 	// tunnel: "https://us-central1-redistricter.cloudfunctions.net/tunnel",
// });

// ensure firebase config is available
getFirebase();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	// turned off strict mode because it was causing use effect to trigger twice

	// <React.StrictMode>
	<AppSettingsProvider>
		<App />
	</AppSettingsProvider>
	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
