import React, { useEffect, useCallback } from "react";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { getFirebase } from "../lib/firebase";

export function SignOutPage() {
  const navigate = useNavigate();
  const { auth } = getFirebase();

  const sendEmailPaymentSuccess = useCallback(async () => {
    try {
      await fetch(
        "https://us-central1-redistricter.cloudfunctions.net/sendEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            email: auth.currentUser.email,
            stripePayment: true
          })
        }
      );
    } catch (error) {
      console.log("payment email error: " + error?.message);
    }
  }, [auth]);

  useEffect(() => {
    const signOutUser = async () => {
      try {
        sessionStorage.clear();
        await signOut(auth);
        // alert('You have been signed out');
        navigate("/signin");
      } catch (error) {
        // alert('Error signing out: ', error);
      }
    };
    sendEmailPaymentSuccess();
    signOutUser();
  }, [navigate, auth, sendEmailPaymentSuccess]);

  return <p>Signing out...</p>;
}
