import React from 'react';
import changelog from '../changelog.json';
import { createUseStyles } from 'react-jss';
import { Helmet } from 'react-helmet';

interface Update {
    date: string;
    featureAdditions?: string[];
    dataAdditions?: string[];
}

const useStyles = createUseStyles({
    main: {
        marginTop: 100,
        marginBottom: 50,
        paddingLeft: 50,
        paddingRight: 50,
        maxWidth: 700,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    title: {
        fontSize: 32,
        fontWeight: 600,
        marginBottom: 30,
    },
    date: {
        fontSize: 24,
        fontWeight: 500,
        marginBottom: 0,
        marginTop: 40,
    },
    changeListContainer: {
        paddingLeft: 15,
    },
    changeList: {
        // listStyleType: 'none',
        // listStyleType: 'disc',
        paddingBottom: 5,
        "& a": {
            color: 'rgb(99, 99, 255)',
            textDecoration: 'none',
        },
    },
    changeListSection: {
        fontWeight: 600,
        fontSize: 16,
        marginBottom: -5,
        marginTop: 10,
    },
    subText: {
        color: 'rgb(70, 70, 70)',
        marginTop: 30,
        marginBottom: 50,
        "& a": {
            color: 'rgb(99, 99, 255)',
            textDecoration: 'none',
        },
    }
});

export const Changelog: React.FC = () => {
    const styles = useStyles();

    return (
        <div className={styles.main}>
            <Helmet>
                <title>Changelog</title>
                <meta name="description" content="All of the recent Redistricter additions and changes" />
            </Helmet>
            <h1 className={styles.title}>Changelog</h1>
            {changelog.updates.map((update: Update, index: number) => (
                <div key={index}>
                    <h2 className={styles.date}>
                        {new Date(update.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                    </h2>
                    {update.featureAdditions && update.featureAdditions.length > 0 && (
                        <>
                            <h3 className={styles.changeListSection}>Feature Additions</h3>
                            <ul className={styles.changeListContainer}>
                                {update.featureAdditions.map((change, i) => (
                                    <li key={i} className={styles.changeList} dangerouslySetInnerHTML={{ __html: change }}></li>
                                ))}
                            </ul>
                        </>
                    )}
                    {update.dataAdditions && update.dataAdditions.length > 0 && (
                        <>
                            <h3 className={styles.changeListSection}>Election Data Additions</h3>
                            <ul className={styles.changeListContainer}>
                                {update.dataAdditions.map((change, i) => (
                                    <li key={i} className={styles.changeList} dangerouslySetInnerHTML={{ __html: change }}></li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
            ))}
            <p className={styles.subText}>All updates before January 8, 2024 are documented on my <a href="https://twitter.com/Redistricter">Twitter</a></p>
        </div>
    );
};