import React, { useState, useEffect } from "react";
import styles from "./message.module.css";
import { useDisclosure } from "@mantine/hooks";
import { Modal, Checkbox, Button } from "@mantine/core";
import Cookies from "js-cookie";
import { getFirebase } from "../lib/firebase";
import useUserRole from "../hooks/useUserRole";
// import useAppSettings from "../hooks/useAppSettings";

const SHOW_MESSAGE = false;
// TODO: Make this not show for users not signed in

export function Message() {
  // const { getStripeRole } = useAppSettings();
  // const stripeRole = getStripeRole();
  const [showMessage, setShowMessage] = useState(false);
  const [opened, { close }] = useDisclosure(true);
  const [dontShowChecked, setdontShowChecked] = useState(false);
  const { auth } = getFirebase();
  const { role: stripeRole } = useUserRole(auth.currentUser?.uid, auth.currentUser?.email);
  // const [isSelected, setSelected] = useState(false);

  useEffect(() => {
    const welcomeMessageSeen = Cookies.get('messageUkClosed');
    if (!welcomeMessageSeen && SHOW_MESSAGE) {
      setShowMessage(true);
    } else {
      console.log("not showing message because it was already seen")
    }
  }, []);

  if (!showMessage) {
    return null;
  }

  if (!stripeRole) {
    return null;
  }

  const hasUkAccess = stripeRole === "Organization" || stripeRole === "Admin";

  return (
    <div className={styles.main}>
      <Modal
        opened={opened}
        onClose={() => {
          if (dontShowChecked) {
            Cookies.set("messageUkClosed", "true", { expires: 365 });
          }
          close();
        }}
        size="lg"
        overlayProps={{
          opacity: 0.55,
          blur: 3
        }}
        xOffset={"-20vw"}
        className={styles.modal}
        centered
      >
        <h1 style={{ marginBottom: 10, fontSize: 20, fontWeight: 600 }}>Redistricter UK</h1>
        {hasUkAccess ? (
          <p style={{ lineHeight: 1.5 }}>
            Redistricter UK is now live! It is currently limited in scope to solely demographic data for now
            but I'm working on expanding it to include more data and features.<br/><br/>
            As a commercial plan user, you can access Redistricter UK at no additional cost.
          </p>
        ) : (
          <p style={{ lineHeight: 1.5 }}>
            Redistricter UK is now live! It is currently limited in scope to solely demographic data for now
            but I'm working on expanding it to include more data and features.<br/><br/>
            To access the UK data you must be a subscriber on the commercial plan.
          </p>
        )}

        {!hasUkAccess && (
          <div>
            <Button
              onClick={() => {
                window.open("/signup/commercial", "_blank");
              }}
              fullWidth
              style={{ marginBottom: 20, backgroundColor: 'blue' }}
            >
              Upgrade your subscription
            </Button>

            <Button
              onClick={() => {
                if (dontShowChecked) {
                  Cookies.set("messageUkClosed", "true", { expires: 365 });
                }
                close();
              }}
              fullWidth
              color="gray"
              variant="outline"
              style={{ marginBottom: 20 }}
            >
              Continue on current plan
            </Button>
          </div>
        )}

        <Checkbox
          checked={dontShowChecked}
          onChange={() => {
            setdontShowChecked(!dontShowChecked);
            console.log(dontShowChecked);
          }}
          label="Don't show this message again"
          style={{ marginTop: 10 }}
        />
      </Modal>
    </div>

    // {/* <div style={{ 
    //   padding: '10px',
    //   paddingLeft: '20px',
    //   paddingRight: '20px'
    // }}>
    // <h1>1990-2010 demographic data has been added</h1>
    // <p>
    //   Redistricter's data library has been expanded significantly with 
    //   new demographic data from every census since 1990. New features include:
    // </p>
    //   <li style={{ marginBottom: '5px' }}>A new population/racial change dataset</li>
    //   <li style={{ marginBottom: '5px' }}>The ability to draw districts with 1990/2000/2010 population data</li>
    //   <li style={{ marginBottom: '5px' }}>New settings to change the census dataset used for the density fill mapmode</li>

    // <p>More information can be found{" "}
    //   <a
    //     href="https://x.com/Redistricter/status/1722432114946085336"
    //     target="_blank"
    //     rel="noreferrer"
    //   >
    //     here
    //   </a>
    //   .
    // </p>
    // </div> */}

    //   {/* <p>
    //   I've started a newsletter where I'll be sending out regular updates on
    //   Redistricter to subscribers. If you haven't already, you can join the
    //   newsletter{" "}
    //   <a
    //     href="https://redistricter.com/account"
    //     target="_blank"
    //     rel="noreferrer"
    //   >
    //     here on the account page
    //   </a>
    //   .
    // </p> */}

    // <div className={styles.main}>
    //     <Modal
    //         opened={opened}
    //         onClose={() => {
    //             // if (dontShowChecked) {
    //             //     Cookies.set('message2Closed', 'true', { expires: 365 });
    //             // }
    //             close();
    //         }}
    //         size='lg'
    //         overlayProps={{
    //             // color: 'black',
    //             opacity: 0.55,
    //             blur: 3,
    //         }}
    //         xOffset={'-20vw'}
    //         className={styles.modal}
    //         centered
    //     >
    //         <h1>Welcome to the closed Redistricter beta</h1>
    //         <p>Redistricter is in an early stage of development right now so expect bugs, crashes, and missing features/data.
    //         Right now I just have Texas (and Delaware) because I want to hammer out everything with Redistricter itself before I move onto adding more data.</p>
    //         <p>
    //             I appreciate yall for giving Redistricter a shot especially considering where it is right now. I also appreciate everyone helping me compile election data, it helps a lot.
    //             Don't be afraid to <a href='mailto:colin@redistricter.com'>email</a> or <a href='https://twitter.com/redistricter'>DM</a> me with any questions, comments, or bug reports.
    //         </p>
    //         <p>-Colin</p>
    //         <Checkbox
    //             checked={dontShowChecked}
    //             onChange={() => {
    //                 setdontShowChecked(!dontShowChecked);
    //                 console.log(dontShowChecked);
    //             }}
    //             label="Don't show this message again"
    //         />

    //     </Modal>
    // </div>
  );
}
