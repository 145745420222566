import React, { useEffect, useState, useRef } from 'react';
import { getFirebase } from "../lib/firebase";
import { Button, Text, Modal, Anchor } from '@mantine/core';
import useUserRole from '../hooks/useUserRole';
import { PRICES } from '../prices';

export function SwitchToYearly(
    // isCommercial: boolean, userCount: number
) {
    const { auth } = getFirebase();
    const [userId, setUserId] = useState(null);
    const loading = useRef(true);
    const { role: stripeRole } = useUserRole(auth.currentUser?.uid, auth.currentUser?.email);
    const [error, setError] = useState<String | null>(null);
    const [price, setPrice] = useState(PRICES.individual.yearly);
    const [userCount, setUserCount] = useState(1);
    const [isCommercial, setIsCommercial] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [success, setSuccess] = useState(false);
    // const [activeSubscriptionEnd, setActiveSubscriptionEnd] = useState<number | null>(null);

    useEffect(() => {
        if (isCommercial) {
            setPrice(PRICES.commercial.yearly * userCount);
        } else {
            setPrice(PRICES.individual.yearly);
        }
    }, [isCommercial, userCount]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user: any) => {
            if (user) {
                setUserId(user.uid);
            }
        });

        // Clean up the listener when the component unmounts
        return () => unsubscribe();
    }, [auth]);

    useEffect(() => {
        if (userId) {
            console.log('User id: ' + userId, 'role: ', stripeRole);

            if (!stripeRole || stripeRole === 'None') {
                // console.warn('User has no active subscription to upgrade');
                setError('No active subscription to upgrade, please contact support this was in error.');
            } else {
                setError(null);
                if (stripeRole === 'Organization') {
                    setIsCommercial(true);
                }
                if (userId === "C6IiwjMkJjTBUsjGLyDvfALzQvq1") {
                    setUserCount(3);
                }
                loading.current = false;
            }
        } else {
            console.log('No user id');
        }
    }, [stripeRole, userId]);

    async function callCreateCheckoutSession(
        userId: string,
        isCommercial: boolean,
        userCount: number,
        success_url = 'https://redistricter.com',
        cancel_url = 'https://redistricter.com/account'
    ) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userId, isCommercial, userCount, success_url, cancel_url })
        };

        // const functionUrl = 'http://127.0.0.1:5001/redistricter/us-central1/upgradeToYearly';
        const functionUrl = 'https://us-central1-redistricter.cloudfunctions.net/upgradeToYearly';

        fetch(functionUrl, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if (data.newAmount) {
                    setSuccess(true);
                }
                // setSessionUrl(data.sessionUrl);
                // setActiveSubscriptionEnd(data.activeSubscriptionEnd);
                return data;
            })
            .then(data => loading.current = false)
            // .then(data => window.open(`https://checkout.stripe.com/pay/${data.sessionId}`, '_blank'))
            // .catch(error => setError(`Error: ${error}`));
            .catch(error => setError(`Error upgrading subscription. Subscription may already be yearly.`));
    }

    async function handleButtonClick() {
        if (userId) {
            loading.current = true;
            setIsModalOpen(false);
            // console.log('sessionId: ' + sessionUrl);

            await callCreateCheckoutSession(
                userId,
                // "kC89HVcwfaVEvFiGuCsh2xKulCu2",
                // "a71miMG35ic5oKd6FDS75yrzvhg2",
                isCommercial,
                userCount,
                'https://redistricter.com',
                'https://redistricter.com/switchToYearly'
            );

            console.log('Price updated');
        }
    }

    return (
        <div style={{ top: '50vh', left: '50vw', position: 'absolute', transform: 'translate(-50%, -50%)', width: 500 }}>
            <Button
                // onClick={handleButtonClick}
                onClick={() => setIsModalOpen(true)}
                // loading={loading.current && !error}
                disabled={(error !== null || success)}
                size="lg"
                style={{ marginLeft: 50, marginRight: 50, width: 'calc(100% - 100px)', marginBottom: 20 }}
            >
                {/* Start Checkout Session */}
                Upgrade to Yearly Plan {price && `($${price}/yr*)`}
            </Button>
            {/* {(!error && activeSubscriptionEnd) && */}
            {(!error && !success) &&
                <>
                    <Text size="sm" style={{ textAlign: 'center', marginBottom: 10 }}>
                        This will switch your monthly subscription to a yearly plan. You will be charged for the year immediately.
                    </Text>
                    <Text size="sm" style={{ textAlign: 'center', marginBottom: 10 }}>
                        *The price you pay may be lower as it is prorated based on the time remaining in your current subscription.

                        {/* When your current subscription ends on {new Date(activeSubscriptionEnd * 1000).toLocaleDateString()}, your new yearly subscription will start. */}
                    </Text>
                </>
            }
            <Text size="sm" color="red" style={{ textAlign: 'center' }}>
                {error}
            </Text>
            {
                success &&
                <Text size="md" style={{ textAlign: 'center' }}>
                    Your subscription has been updated.
                </Text>
            }
            <Anchor style={{ marginTop: 50 }} href="mailto:colin@redistricter.com" target="_blank">
                Having issues upgrading? Contact me.
            </Anchor>
            {/* <Text size="sm" style={{ marginTop: 50 }}>Having issues upgrading? <a href="mailto:colin@redistricter.com ">Contact me</a>.</Text> */}

            <Modal
                opened={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                // title={confirm_purchase_text}
                centered
                style={{
                    // position: 'absolute',
                    // top: '50vh',
                    // left: '50vw',
                    // transform: 'translate(-50%, -50%)',
                    // width: 500,
                }}
            >
                <Text>
                    Confirm Purchase of ${price}*?
                </Text>
                <Text style={{ marginTop: 15 }} size="sm">
                    *The price you pay may be lower as it is prorated based on the time remaining in your current subscription.
                </Text>
                <Button
                    onClick={handleButtonClick}
                    loading={loading.current && !error}
                    disabled={(error !== null || success)}
                    style={{ marginTop: 20 }}
                    fullWidth
                >
                    Confirm Purchase
                </Button>
            </Modal>

        </div>
    );
}