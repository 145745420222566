import React from "react";
import styles from "./tos.module.css";

export function Tos() {
	return (
		<div className={styles.main}>
			<h1>Terms of Service</h1>
			<p>
				Welcome to Redistricter. This document describes the Terms of Service
				("Terms") governing the use of Redistricter, a redistricting map
				creation and sharing platform ("Service") owned and operated by
				Redistricter, LLC. ("the Company", "We", "Us" or "Our").
			</p>

			<h2>Acceptance of Terms</h2>
			<p>
				By accessing and using our Service, you agree to be bound by these
				Terms. If you do not agree with any part of the Terms, you should
				discontinue access and use of the Service immediately.
			</p>

			<h2>Services Description and Subscription</h2>
			<p>
				Our Service provides users with the tools to create and share
				redistricting maps. The company offers subscription tiers for
				individuals and organizations. The subscription for organizations/commercial subscription is
				only available by quote. Subscriptions are personal to the subscriber
				and may not be shared, transferred, or sold to another individual or
				entity. Certain commercial-tier subscriptions may have different
				terms regarding privacy and usage of their created maps and data,
				agreed upon separately. Unauthorized sharing or transfer of
				subscriptions will be considered a violation of these Terms and may
				result in termination of the subscription.
			</p>

			<h2>User Content</h2>
			<p>
				Users may create and share redistricting maps ("User Content") on our
				platform. By creating or sharing User Content, you grant us an
				irrevocable, non-exclusive, worldwide, royalty-free license to use,
				copy, display, and distribute your User Content for any purpose,
				unless otherwise agreed upon in the terms of certain commercial-tier
				subscriptions. Unless expressly agreed upon in writing, User Content
				is not considered private or confidential.
			</p>

			<h2>Prohibited Use</h2>
			<p>
				Users are strictly prohibited from using any data, maps, or other
				content obtained from Redistricter for commercial purposes or for
				government use, without our prior written permission, unless otherwise
				stipulated in the terms of certain commercial-tier subscriptions.
				Commercial and government purposes include but are not limited to:
				lobbying, official redistricting, political campaigns, and political
				consulting. Violation of this provision may result in immediate
				termination of your subscription and may also subject you to legal
				action.
			</p>

			<h2>Data Scraping and Unauthorized Access</h2>
			<p>
				It is explicitly forbidden for users to engage in data scraping, downloading, or any other unauthorized extraction of map shapefile data or any other content from Redistricter.
				This prohibition extends to both manual and automated processes.
				Any unauthorized access or attempt to retrieve data without explicit permission is a violation of our terms of service and may lead to account suspension, legal consequences, or both.
			</p>

			<h2>Privacy Policy</h2>
			<p>
				Protecting your privacy is a priority for us.
				We will collect and use your personal data only in accordance with our Privacy Policy, which forms an integral part of these Terms of Service.
				We encourage you to review our Privacy Policy to understand our practices.
			</p>

			<h2>Changes to These Terms</h2>
			<p>
				We reserve the right to modify or replace these Terms at any time.
			</p>

			<h1>Privacy Policy</h1>
			<p>
				Your privacy is very important to us. This Privacy Policy explains
				what information we collect from you, how it is used, how it is
				protected, and your rights concerning that information.
			</p>

			<h2>Cookies</h2>
			<p>
				We use "cookies" to collect information to improve our services for
				you. You have the ability to accept or decline cookies. Most Web
				browsers automatically accept cookies, but you can modify your browser
				setting to decline cookies if you prefer.
			</p>

			<h2>Children's Privacy</h2>
			<p>
				Our Service is not designed or intended for use by children under the
				age of 13. We do not knowingly collect personal data from children
				under 13. If we become aware that we have collected personal data from
				a child under the age of 13, we will take steps to remove that data
				and terminate the child's account.
			</p>

			<h1>Contact</h1>
			<p>
				If you have any questions about these Terms of Service or Privacy
				Policy, feel free to email the company at{" "}
				<a className={styles.link} href="mailto:colin@redistricter.com">
					colin@redistricter.com
				</a>
				.
			</p>
		</div>
	);
}
