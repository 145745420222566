import React, { useLayoutEffect, useRef, useState } from "react";
import * as d3 from "d3";
// import { useMediaQuery } from "@mantine/hooks";
import { Slider } from "@mantine/core";
import { FiArrowRight } from "react-icons/fi";
// import { FiArrowRight } from "react-icons/fi";

function getSlider(
    title: string,
    value: number,
    setValue: (value: number) => void
    // isMobile: boolean
) {
    return (
        <div>
            <p className="mt-2 mb-0.5 font-medium text-sm">{title}</p>
            <Slider
                value={value}
                onChange={setValue}
                label={(value: number) =>
                    value > 0
                        ? `D+${value}`
                        : value < 0
                        ? `R+${Math.abs(value)}`
                        : "Even"
                }
                min={-40}
                max={40}
                marks={
                    // !isMobile
                    // ?
                    // [
                    //     { value: -20, label: "R+20" },
                    //     { value: 0, label: "Even" },
                    //     { value: 20, label: "D+20" }
                    // ]
                    // :
                    [{ value: -20 }, { value: 0 }, { value: 20 }]
                }
            />
        </div>
    );
}

export default function Simulation() {
    const svgRef = useRef<SVGSVGElement>(null);
    const [baseShift, setBaseShift] = useState(0);
    const [collegeShift, setCollegeShift] = useState(0);
    const [noCollegeShift, setNoCollegeShift] = useState(0);
    const [whiteShift, setWhiteShift] = useState(0);
    const [blackShift, setBlackShift] = useState(0);
    // const isMobile = useMediaQuery("(max-width: 1024px)");
    // const [otherShift, setOtherShift] = useState(0);

    useLayoutEffect(() => {
        // Load your SVG map
        d3.xml("./demoMap.svg").then((data: any) => {
            if (!svgRef.current) {
                console.warn("ref.current is null");
                return;
            }

            const node = d3.select(svgRef.current).node();
            if (!node) {
                console.warn("node is null");
                return;
            }

            // Append the SVG only if it hasn't been appended before
            if (svgRef.current.children.length === 0) {
                svgRef.current.appendChild(data.documentElement);
            }

            const svg = d3.select(svgRef.current).select("svg");
            // Define your color scale
            const demColorScale = d3
                .scaleQuantize<string>()
                .domain([0.5, 1])
                .range([
                    "#dee8fb",
                    "#b4c7ec",
                    "#8da9e2",
                    "#678cd7",
                    "#4170cd",
                    "#3358a2",
                    "#244079"
                ]);

            const repColorScale = d3
                .scaleQuantize<string>()
                .domain([0, 0.5])
                .range([
                    "#850400",
                    "#b00600",
                    "#d02923",
                    "#e55651",
                    "#ed8783",
                    "#f1b4b2",
                    "#fbdedd"
                ]);

            // Apply color to each property based on votes
            svg.selectAll("path")
                .style("fill", function (d: any, i: number) {
                    function getElement(this: SVGElement, id: string) {
                        const element =
                            this instanceof SVGElement
                                ? this.getAttribute(id)
                                : null;
                        return element ? Number(element) : 0;
                    }

                    const demVotes = getElement.call(
                        this as SVGElement,
                        "data-g20pred"
                    );
                    const repVotes = getElement.call(
                        this as SVGElement,
                        "data-g20prer"
                    );
                    const dhighschool = getElement.call(
                        this as SVGElement,
                        "data-dhighschool"
                    );
                    const dcollege = getElement.call(
                        this as SVGElement,
                        "data-dcollege"
                    );
                    const dwhitevap = getElement.call(
                        this as SVGElement,
                        "data-dwhitevap"
                    );
                    const dblackvap = getElement.call(
                        this as SVGElement,
                        "data-dblackvap"
                    );
                    const dvap = getElement.call(
                        this as SVGElement,
                        "data-dvap"
                    );

                    const collegePrecinctShift: number =
                        (dcollege / (dhighschool + dcollege)) * collegeShift;
                    const noCollegePrecinctShift: number =
                        (dhighschool / (dhighschool + dcollege)) *
                        noCollegeShift;
                    const whitePrecinctShift: number =
                        (dwhitevap / dvap) * whiteShift;
                    const blackPrecinctShift: number =
                        (dblackvap / dvap) * blackShift;

                    // const dwhiteAttr = this instanceof SVGElement ? this.getAttribute('data-dwhitevap') : null;
                    // const dwhite: number = dwhiteAttr ? Number(dwhiteAttr) : 0;

                    // Calculate percentage of dem votes
                    const totalVotes: number = demVotes + repVotes;
                    let demPercentage: number =
                        totalVotes !== 0 ? demVotes / totalVotes : 0;
                    demPercentage +=
                        (baseShift +
                            collegePrecinctShift +
                            noCollegePrecinctShift +
                            whitePrecinctShift +
                            blackPrecinctShift) /
                        100;

                    // Return color based on percentage
                    if (totalVotes <= 19) {
                        return "#c7c7c7";
                    } else if (demVotes === repVotes) {
                        // return "#AE8BB1";
                        return "#c7c7c7";
                    } else if (demPercentage > 0.5) {
                        return demColorScale(demPercentage);
                    } else {
                        return repColorScale(demPercentage);
                    }
                })
                .style("stroke", function (d: any, i: number) {
                    // Get the fill color
                    const fillColor: string = (this as SVGElement).style.fill;
                    return fillColor;
                })
                .style("stroke-width", "1px");
            // .style('stroke', 'white') // Add this line to add a border around the paths
            // .style('stroke-width', '0.5px'); // Adjust the stroke width

            svg.attr("width", "100%").attr("height", "100%");

            const svgElement = svgRef.current;
            if (svgElement) {
                // Get the bounding box of the SVG content
                const bbox = svgElement.getBBox();
                // Set the viewBox attribute using the dimensions of the bounding box
                svgElement.setAttribute(
                    "viewBox",
                    `0 0 ${bbox.width} ${bbox.height}`
                );
            }
        });
    }, [baseShift, blackShift, collegeShift, noCollegeShift, whiteShift]);

    return (
        <div className="landingPageSection pb-5 bg-gradient-to-br from-violet-700 to-fuchsia-800 text-white">
            <p className="landingPageTitle text-center">
                Simulate your own elections
            </p>
            {/* <p className="landingPageSubtitle mb-5 text-center"> */}
            <p className="landingPageSubtitle mb-3 text-center">
                Build your own election models and simulate results down to the
                precinct-level
            </p>
            <div className="mx-auto flex justify-center">
                <a
                    className="text-lg my-0 ml-1/2 flex gap-2 p-2 mb-8 transition-all duration-300"
                    href="https://docs.redistricter.com/guide/simulation"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Learn more
                    <FiArrowRight className="h-auto w-[full] my-auto transition-all duration-300" />
                </a>
            </div>
            <div className="flex flex-col md:flex-row mx-5 bg-neutral-50 text-black rounded-[10px]">
                <div className="flex-1 flex flex-col justify-around p-4 pt-4 sm:pt-0 md:pt-4 h-auto">
                    {getSlider("Baseline", baseShift, setBaseShift)}
                    {getSlider(
                        "College-educated",
                        collegeShift,
                        setCollegeShift
                    )}
                    {getSlider(
                        "Non college-educated",
                        noCollegeShift,
                        setNoCollegeShift
                    )}
                    {getSlider("White", whiteShift, setWhiteShift)}
                    {getSlider("Black", blackShift, setBlackShift)}
                </div>
                <div className="flex-1">
                    <svg ref={svgRef} className="w-full h-full p-4" />
                </div>
            </div>
        </div>
    );
}
