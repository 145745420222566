import { calculateDaysRemaining } from "../../lib/helpers";

/*
 * Admins
 */
const adminEmail = "colin@redistricter.com";

/*
 * Organizations
 */
// Last checked November 9
const organizationEmails = [
  "patrick@echeloninsights.com",
  "ryan.doogan@echeloninsights.com",
  "jonathan.detroye@echeloninsights.com",
  "wilzonthebuilder@gmail.com", 
  "austin@redskyservices.com",
  "samsob30@gmail.com",
  "rbhockema@gmail.com"
];

/*
 * Individuals
 */
// Last checked November 9
const individualEmails = [
  "info@francinelyforcongress.com",
  // "henryzcohen@gmail.com",
  "graysonthrush2003@gmail.com",
  "tjones.disd@gmail.com",
  // "alexanderkuchar2@outlook.com",
  "jordancmorrissette@gmail.com",
  "ezperkins@gmail.com",
  "jsjesperson@gmail.com",
  "spitfireace82@gmail.com",
  "ilovemykitty0@gmail.com",
  "timothy.hruz@gmail.com",
  "ntreib05@gmail.com", // TODO: Cancel on Nov. 26
  "ykharrati@gmail.com", // Goofball who somehow locked himself out of the paying email :/ actual email is ykharrati02@gmail.com
  "charlesbrewer500@gmail.com",
  "zackareytomko08@gmail.com",
  "Crandersonschool@gmail.com",
  "marcelholos21@gmail.com",
  "realmattsharpsteen@gmail.com",
  "mcwoolybradleyub@gmail.com",
  "rlocher1052@gmail.com",
  "efrain.jperez102902@gmail.com",
  "braadenstough05@gmail.com",
  "jmhoekstra2004@gmail.com",
  "tckid08@gmail.com",
  "kpkomedy51@gmail.com",
  "n.a.coombs@gmail.com",
  "millerconnor2000@gmail.com",
  "jacobahlstrand1@gmail.com",
  "xander.ab20@gmail.com",
  "echoate@gmail.com",
  "pat_bridgenorth@hotmail.com",
  "sheddenw@gmail.com",
  "ammeraz@gmail.com",
  "gkzaour@my.loyno.edu",
  "brisingr2.0@gmail.com",
  "sr1263@georgetown.edu",
  "jakeynel@icloud.com",
  "cgoodwin10cg@gmail.com",
  "legoeddie624@gmail.com",
  "benbaharlias@gmail.com",

  // Test account:
  // "vehagot587@cwtaa.com",
  // "jejeget991@pbridal.com"
];

/*
 * Trials
 */
const trialEmails = {
  // "nrakich@gmail.com": "2023-10-20",
  // "Mike.Parisi@alston.com": "2023-10-08",
  // "ctvtskg@gmail.com": "2023-11-20", // As a thanks for pointing out bug that allowed non paying users to make maps
  // "Dwasserman@cookpolitical.com": "2023-09-27",
  // "sternkev@gmail.com": "2023-10-12",
  // "hayden.dunson@gmail.com": "2023-10-19",
  "Samdillenbeck1@gmail.com": "2023-12-27",
  "jace@jrand.com": "2024-10-04",
  "Adi.Texas@gmail.com": "2024-10-19",

  // Test account:
  // "jejeget991@pbridal.com": "2023-09-27",
};

export const getRole = async (
  user: any,
  auth: any
): Promise<{ role: string; daysLeft?: number; email: string }> => {
  if (!user) {
    console.log("No user");
    return Promise.resolve({ role: "", email: "" });
  }

  const decodedToken = await auth.currentUser.getIdTokenResult();

  const email = decodedToken.claims.email;

  if (email === adminEmail) {
    return { role: "Admin", email };
  }

  if (email in trialEmails) {
    return {
      role: "Trial",
      email,
      daysLeft: calculateDaysRemaining(
        trialEmails[email as keyof typeof trialEmails]
      )
    };
  }

  if (individualEmails.includes(email)) {
    return { role: "Individual", email };
  }

  if (organizationEmails.includes(email)) {
    return { role: "Organization", email };
  }

  console.log("Stripe Role: ", decodedToken.claims.stripeRole);
  return { role: decodedToken.claims.stripeRole, email };
};
