import React, { useRef, useState } from "react";
import type { JSX } from "react";

interface FAQItemProps {
    question: string;
    answer: JSX.Element;
}

const faqList: FAQItemProps[] = [
    {
        question: "Who uses Redistricter?",
        answer: (
            <div className="space-y-2 leading-relaxed">
                Redistricter is a data visualization and analysis tool
                originally created explicitly for Redistricting purposes but
                now is mainly used for other purposes by political analysists/consultants, journalists, political junkies, and academics.
            </div>
        )
    },
    {
        question: "Can I make multistate maps?",
        answer: (
            <div className="space-y-2 leading-relaxed">
                Yes. You can make maps that combine multiple states together.
                You can also make maps of only specific counties.
            </div>
        )
    },
    // {
    //     question: "What election data does Redistricter have?",
    //     answer: (
    //         <div className="space-y-2 leading-relaxed">
    //             A full list of election data by state can be found{" "}
    //             <a href="/elections" className="underline text-blue-800">
    //                 here
    //             </a>
    //             .
    //         </div>
    //     )
    // },
    {
        question: "Can I export districts that I made in Redistricter?",
        answer: (
            <div className="space-y-2 leading-relaxed">
                Yes, individual subscriptions can export district shapefiles.
                Commercial subscriptions can export district shapefiles with all of
                Redistricter's demographic and election data attatched.
            </div>
        )
    },
    {
        question:
            "How can I access Redistricter's raw data for use outside of Redistricter?",
        answer: (
            <div className="space-y-2 leading-relaxed">
                You can get a quote for a Redistricter data license{" "}
                <a href="/quote" className="underline text-blue-800">
                    here
                </a>
                .
            </div>
        )
    },
    {
        question: "Can I export my maps to images?",
        answer: (
            <div className="space-y-2 leading-relaxed">
                Yes, you can <a href="https://docs.redistricter.com/guide/export-img/" className="underline text-blue-800" target="_blank" rel="noopener noreferrer">export your maps to SVGs</a> with options to
                automatically style/color them by election results, add county
                overlays/names/filters, and more.
            </div>
        )
    },
    {
        question: "Can I load existing district lines into Redistricter?",
        answer: (
            <div className="space-y-2 leading-relaxed">
                Yes, congressional district maps used in the 2020, 2022, and
                2024 elections are available as presets. For other maps you can
                upload their shapefiles into Redistricter's import tool.
            </div>
        )
    },
    {
        question: "I have another question",
        answer: (
            <div className="space-y-2 leading-relaxed">
                <a
                    href="mailto:colin@redistricter.com"
                    className="underline text-blue-800"
                >
                    Email me.
                </a>
            </div>
        )
    }
];

const FaqItem = ({ item }: { item: FAQItemProps }) => {
    const accordion = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <li>
            <button
                className="relative flex gap-2 items-center w-full py-5 text-base font-semibold text-left border-t md:text-lg border-base-content/10"
                onClick={(e) => {
                    e.preventDefault();
                    setIsOpen(!isOpen);
                }}
                aria-expanded={isOpen}
            >
                <span
                    className={`flex-1 text-base-content ${
                        isOpen ? "text-primary" : ""
                    }`}
                >
                    {item?.question}
                </span>
                <svg
                    className={`flex-shrink-0 w-4 h-4 ml-auto fill-current`}
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        y="7"
                        width="16"
                        height="2"
                        rx="1"
                        className={`transform origin-center transition duration-200 ease-out ${
                            isOpen && "rotate-180"
                        }`}
                    />
                    <rect
                        y="7"
                        width="16"
                        height="2"
                        rx="1"
                        className={`transform origin-center rotate-90 transition duration-200 ease-out ${
                            isOpen && "rotate-180 hidden"
                        }`}
                    />
                </svg>
            </button>

            <div
                ref={accordion}
                className={`transition-all duration-300 ease-in-out opacity-80 overflow-hidden`}
                style={
                    isOpen
                        ? {
                              // @ts-ignore
                              maxHeight: accordion?.current?.scrollHeight,
                              opacity: 1
                          }
                        : { maxHeight: 0, opacity: 0 }
                }
            >
                <div className="pb-5 leading-relaxed">{item?.answer}</div>
            </div>
        </li>
    );
};

export default function Faq() {
    return (
        <section className="landingPageSection" id="faq">
            <div className="py-16 md:py-20 px-5 max-w-7xl mx-auto flex flex-col md:flex-row gap-12">
                <div className="flex flex-col text-left basis-1/2">
                    <p className="inline-block font-semibold text-primary mb-4">
                        FAQ
                    </p>
                    <p className="sm:text-4xl text-3xl font-extrabold text-base-content">
                        Frequently Asked Questions
                    </p>
                </div>

                <ul className="basis-1/2">
                    {faqList.map((item, i) => (
                        <FaqItem key={i} item={item} />
                    ))}
                </ul>
            </div>
        </section>
    );
}
