import React, { useEffect, useState } from "react";
import { FiArrowRight } from "react-icons/fi";

const datasets = [
    {
        name: "Education",
        data: [
            { name: "High School", value: 1433, percentage: 94.78 },
            { name: "Bachelor", value: 307, percentage: 20.3 },
            { name: "Graduate", value: 104, percentage: 6.88 }
        ]
    },
    {
        name: "Monthly Rent",
        data: [
            { name: "Total", value: "$1,346,829", percentage: null },
            { name: "Average", value: "$3,238", percentage: null }
        ]
    },
    {
        name: "2012 Governor Turnout",
        data: [{ name: "Turnout", value: "185", percentage: 67.49 }]
    },
    {
        name: "2006 Senate",
        data: [
            {
                name: "Democrat",
                value: "65",
                percentage: 31.5,
                progressColor: "bg-blue-500"
            },
            {
                name: "Republican",
                value: "135",
                percentage: 66.5,
                progressColor: "bg-red-500"
            },
            {
                name: "Other",
                value: "2",
                percentage: 2.0
            }
        ]
    },
    {
        name: "County of Work",
        data: [
            { name: "In County", value: 2269, percentage: 68.2 },
            { name: "Out of County", value: 1058, percentage: 31.8 }
        ]
    },
    {
        name: "Race (2020)",
        data: [
            { name: "Total", value: 104, percentage: 100 },
            { name: "White", value: 29, percentage: 27.88 },
            { name: "Black", value: 32, percentage: 30.77 },
            { name: "Asian", value: 9, percentage: 8.65 },
            { name: "Native", value: 2, percentage: 1.92 },
            { name: "Pacific", value: 9, percentage: 8.65 },
            { name: "Multiple", value: 5, percentage: 4.81 },
            { name: "Other", value: 18, percentage: 17.31 }
        ]
    },
    {
        name: "Area & Density",
        data: [
            { name: "Area", value: "1.05 km²", percentage: null },
            { name: "Density", value: "6534.86/km²", percentage: null }
        ]
    },
    {
        name: "Occupied Housing",
        data: [
            { name: "Total Units", value: 2964, percentage: null },
            { name: "Units/Person", value: 0.43, percentage: null },
            { name: "Owned", value: 620, percentage: 20.92 },
            { name: "Rented", value: 2344, percentage: 79.08 }
        ]
    },
    {
        name: "Health Insurance",
        data: [
            { name: "Insured", value: 6452, percentage: 96.01 },
            { name: "Uninsured", value: 268, percentage: 3.99 }
        ]
    },
    {
        name: "Industry of Work",
        data: [
            { name: "Total", value: 1825, percentage: 100 },
            { name: "Agriculture", value: 2, percentage: 0.11 },
            { name: "Construction", value: 85, percentage: 4.66 },
            { name: "Manufacturing", value: 268, percentage: 14.68 },
            { name: "Wholesale", value: 20, percentage: 1.1 },
            { name: "Retail", value: 135, percentage: 7.4 },
            { name: "Transportation", value: 11, percentage: 0.6 },
            { name: "Information", value: 180, percentage: 9.86 },
            { name: "Professional", value: 418, percentage: 22.9 },
            { name: "Education", value: 444, percentage: 24.33 },
            { name: "Arts", value: 193, percentage: 10.58 },
            { name: "Other", value: 34, percentage: 1.86 },
            { name: "Public", value: 34, percentage: 1.86 },
            { name: "Military", value: 2, percentage: 0.02 }
        ]
    },
    {
        name: "2022 Referendum 1",
        data: [
            {
                name: "Yes",
                value: "164",
                percentage: 43.85,
                progressColor: "bg-blue-500"
            },
            {
                name: "No",
                value: "210",
                percentage: 56.15,
                progressColor: "bg-green-500"
            }
        ]
    },
    {
        name: "Latino Population (2000)",
        data: [{ name: "Latino", value: 277, percentage: 9.53 }]
    },
    {
        name: "Poverty Rate",
        data: [{ name: "In Poverty", value: 478, percentage: 7.09 }]
    },
    {
        name: "Commute Time",
        data: [{ name: "Average", value: "33.4 minutes", percentage: null }]
    },
    {
        name: "Method of Commute",
        data: [
            { name: "Total", value: 3298, percentage: 100 },
            { name: "Car", value: 2627, percentage: 79.65 },
            { name: "Transit", value: 417, percentage: 12.64 },
            { name: "Walking", value: 144, percentage: 4.37 },
            { name: "Bike", value: 49, percentage: 1.49 },
            { name: "Other", value: 61, percentage: 1.85 }
        ]
    },
    {
        name: "Origin by Birthplace",
        data: [
            { name: "Domestic", value: 2529, percentage: 70.47 },
            { name: "Out-state", value: 673, percentage: 18.75 },
            { name: "In-state", value: 1830, percentage: 50.99 },
            { name: "Foreign", value: 1060, percentage: 29.53 },
            { name: "Foreign Citizen", value: 572, percentage: 15.94 },
            { name: "Non-Citizen", value: 487, percentage: 13.57 }
        ]
    },
    {
        name: "Simulated Election",
        data: [
            {
                name: "Democrat",
                value: "135",
                percentage: 67.5,
                progressColor: "bg-blue-500"
            },
            {
                name: "Republican",
                value: "65",
                percentage: 32.5,
                progressColor: "bg-red-500"
            }
        ]
    },
    {
        name: "Sex",
        data: [
            { name: "Male", value: 3085, percentage: 49.49 },
            { name: "Female", value: 3149, percentage: 50.51 }
        ]
    },
    {
        name: "Asian Ancestry",
        data: [
            { name: "Total", value: 1439, percentage: 100 },
            { name: "South Asian", value: 241, percentage: 16.75 },
            { name: "Chinese", value: 421, percentage: 29.26 },
            { name: "Filipino", value: 158, percentage: 10.98 },
            { name: "Korean", value: 239, percentage: 16.61 },
            { name: "Japanese", value: 163, percentage: 11.33 },
            { name: "Vietnamese", value: 93, percentage: 6.46 },
            { name: "SE Asian", value: 31, percentage: 2.15 },
            { name: "Other", value: 86, percentage: 5.98 }
        ]
    },
    {
        name: "Marriage Status",
        data: [
            { name: "Straight Marriage", value: 1107, percentage: 86.48 },
            { name: "Gay Marriage", value: 20, percentage: 1.72 },
            { name: "Straight Partner", value: 160, percentage: 12.5 },
            { name: "Gay Partner", value: 13, percentage: 1.02 }
        ]
    },
    {
        name: "Race 1990-2010",
        hideProgressBar: true,
        data: [
            { name: "Total", value: "+63", percentage: "+34%" },
            { name: "White", value: "+8", percentage: "-9%" },
            { name: "Black", value: "+4", percentage: "+0%" },
            { name: "Asian", value: "+23", percentage: "+0%" },
            { name: "Native", value: "+1", percentage: "+0%" },
            { name: "Pacific", value: "+1", percentage: "+0%" },
            { name: "Multiracial", value: "+16", percentage: "+5%" },
            { name: "Other", value: "+10", percentage: "+3%" },
            { name: "Latino", value: "+15", percentage: "+3%" }
        ]
    },
    {
        name: "Personal Income (2000)",
        data: [
            { name: "Total", value: "$101.3M", percentage: null },
            { name: "Average", value: "$23,559", percentage: null }
        ]
    },
    {
        name: "Employment Status",
        data: [
            { name: "Labor Force", value: 3615, percentage: 71.02 },
            { name: "Unemployed", value: 283, percentage: 5.56 }
        ]
    },
    {
        name: "Household Income",
        data: [
            { name: "Total", value: "$19.2M", percentage: null },
            { name: "Average", value: "$112,474", percentage: null },
            { name: "<25k", value: 53, percentage: 30.99 },
            { name: "25-50k", value: 12, percentage: 7.02 },
            { name: "50-100k", value: 35, percentage: 20.47 },
            { name: "100-200k", value: 38, percentage: 22.22 },
            { name: "200k+", value: 32, percentage: 18.71 }
        ]
    },
    {
        name: "State of Work",
        data: [
            { name: "In State", value: 133, percentage: 82.6 },
            { name: "Out of State", value: 28, percentage: 17.4 }
        ]
    },
    {
        name: "Age",
        data: [
            { name: "Under 20", value: 683, percentage: 23.5 },
            { name: "20-39", value: 943, percentage: 32.45 },
            { name: "40-64", value: 827, percentage: 28.46 },
            { name: "65+", value: 455, percentage: 15.66 }
        ]
    },
    {
        name: "2008-2020 Election Shift",
        data: [{ name: "Shift", value: "D+4.82", percentage: null }]
    },
    {
        name: "Race (1990)",
        data: [
            { name: "Total", value: 4557, percentage: 100 },
            { name: "White", value: 1257, percentage: 27.58 },
            { name: "Black", value: 2763, percentage: 60.63 },
            { name: "Asian", value: 313, percentage: 6.87 },
            { name: "Native", value: 23, percentage: 0.5 },
            { name: "Other", value: 148, percentage: 3.25 }
        ]
    },
    {
        name: "Income Change (2000-2020)",
        data: [{ name: "Change", value: "+55.59%", percentage: null }]
    },
    {
        name: "2016 Dem. Pres. Primary",
        data: [
            {
                name: "Clinton",
                value: "30",
                percentage: 48.39,
                progressColor: "bg-pink-500"
            },
            {
                name: "Sanders",
                value: "26",
                percentage: 41.94,
                progressColor: "bg-yellow-500"
            },
            {
                name: "Other",
                value: "6",
                percentage: 9.68
            }
        ]
    },
    {
        name: "Housing Units per Structure",
        data: [
            { name: "Total", value: 2561, percentage: null },
            { name: "Per Capita", value: 0.32, percentage: null },
            { name: "1 detached", value: 540, percentage: 21.09 },
            { name: "1 attached", value: 179, percentage: 6.99 },
            { name: "2-10", value: 750, percentage: 29.29 },
            { name: "10-50", value: 858, percentage: 33.5 },
            { name: "50+", value: 225, percentage: 8.79 },
            { name: "Other", value: 8, percentage: 0.31 }
        ]
    },
    {
        name: "Population Density Change",
        data: [{ name: "1990-2020", value: "+272.21/km²", percentage: null }]
    },
    {
        name: "2022 Governor",
        data: [
            {
                name: "Democrat",
                value: "583",
                percentage: 48.75,
                progressColor: "bg-blue-500"
            },
            {
                name: "Republican",
                value: "613",
                percentage: 51.25,
                progressColor: "bg-red-500"
            }
        ]
    },
    {
        name: "Veteran Status",
        data: [
            { name: "Veteran", value: 162, percentage: 3.22 },
            { name: "Not Veteran", value: 4866, percentage: 96.78 }
        ]
    },
    {
        name: "GINI Inequality",
        data: [{ name: "Index Score", value: 0.46, percentage: null }]
    },
    {
        name: "Language Spoken",
        data: [
            { name: "Only English", value: 2181, percentage: 63.36 },
            { name: "Limited English", value: 399, percentage: 11.59 },
            { name: "Spanish", value: 124, percentage: 3.6 },
            { name: "Indo-European", value: 315, percentage: 9.15 },
            { name: "Asian", value: 768, percentage: 22.31 },
            { name: "Other", value: 54, percentage: 1.57 }
        ]
    },
    {
        name: "2010-2022 Turnout Shift",
        data: [{ name: "Shift", value: "+6.51%", percentage: null }]
    },
    {
        name: "White Ancestry",
        data: [
            { name: "Total", value: 444, percentage: 100 },
            { name: "American", value: 59, percentage: 13.29 },
            { name: "German", value: 50, percentage: 11.26 },
            { name: "British", value: 94, percentage: 21.17 },
            { name: "Irish", value: 69, percentage: 15.54 },
            { name: "Italian", value: 17, percentage: 3.83 },
            { name: "Scandinavian", value: 1, percentage: 0.0 },
            { name: "Dutch", value: 2, percentage: 0.0 },
            { name: "French", value: 7, percentage: 1.58 },
            { name: "East European", value: 30, percentage: 6.76 },
            { name: "Portuguese", value: 1, percentage: 0.0 },
            { name: "Czech", value: 4, percentage: 0.0 },
            { name: "Polish", value: 1, percentage: 0.0 },
            { name: "Balkan", value: 7, percentage: 0.0 },
            { name: "Hungarian", value: 2, percentage: 0.0 },
            { name: "Greek", value: 6, percentage: 0.0 },
            { name: "Brazilian", value: 56, percentage: 12.61 },
            { name: "Arab", value: 28, percentage: 6.31 },
            { name: "Iranian", value: 2, percentage: 0.0 },
            { name: "Other", value: 33, percentage: 7.43 }
        ]
    }
];

function Dataset({ dataset }: { dataset: any }) {
    return (
        <div className="bg-neutral-50 hover:bg-neutral-200 transition-all duration-300 rounded-[5px] mb-2 pt-1 pb-0.5 px-2 w-full shadow-md text-black">
            <h3 className="text-xs font-semibold flex items-center mb-2 mt-1 whitespace-nowrap overflow-hidden text-ellipsis">
                {dataset.name}
            </h3>
            {dataset.data.map((row: any, index: number) => (
                <div
                    className="flex items-center relative w-full text-xs my-1 h-5"
                    key={index}
                >
                    <div className="flex w-full items-center">
                        <div className="w-1/2">
                            <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                                {row.name}
                            </p>
                        </div>
                        <div className="w-1/2 flex justify-between items-center px-1">
                            <p
                                className={`z-10
                                ${!row.percentage ? "ml-auto" : "hidden sm:block"}`}
                            >
                                {row.value.toLocaleString()}
                            </p>
                            {row.percentage && (
                                <>
                                    <p className="z-10 ml-auto sm:ml-0">
                                        {typeof row.percentage === "number"
                                            ? `${Math.round(row.percentage)}%`
                                            : row.percentage}
                                    </p>
                                    {!dataset.hideProgressBar && (
                                        <div
                                            className={`absolute top-0 left-1/2 h-full ${
                                                row.progressColor ||
                                                "bg-gray-400"
                                            } opacity-50 rounded-[4px] z-0`}
                                            style={{
                                                width: `${row.percentage / 2}%`
                                            }}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

function useResponsiveColumns(windowWidth: number) {
    if (windowWidth < 640) return 2; // Tailwind's 'sm' breakpoint
    if (windowWidth < 768) return 3; // Tailwind's 'md' breakpoint
    if (windowWidth < 1024) return 4; // Tailwind's 'lg' breakpoint
    if (windowWidth < 1280) return 5; // Tailwind's 'xl' breakpoint
    return 6;
}

export function Datasets() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const numColumns = useResponsiveColumns(windowWidth);

    // Calculate the total height of all datasets
    // Each dataset has a base height of 2 plus one additional unit per row
    const totalHeight = datasets.reduce(
        (acc, dataset) => acc + 2 + dataset.data.length,
        0
    );
    const targetHeightPerColumn = Math.ceil(totalHeight / numColumns);

    // Function to distribute datasets into columns based on their adjusted height
    const distributeDatasets = () => {
        const columns = Array.from({ length: numColumns }, () => []);
        let currentColumn = 0;
        let currentHeight = 0;

        datasets.forEach((dataset) => {
            const datasetHeight = 2 + dataset.data.length; // Base height of 2 plus one per row
            if (
                currentHeight + datasetHeight > targetHeightPerColumn &&
                currentColumn < numColumns - 1
            ) {
                currentColumn++;
                currentHeight = 0;
            }
            // @ts-ignore
            columns[currentColumn].push(dataset);
            currentHeight += datasetHeight;
        });


        return columns;
    };

    const datasetsColumns = distributeDatasets();

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="landingPageSection bg-gradient-to-br from-teal-100 to-emerald-200 w-full h-[70vh] overflow-hidden relative">
            <div className="mb-8">
                <p className="landingPageTitle text-center py-0 pb-5">
                    Unparalleled data access
                </p>
                <p className="landingPageSubtitle text-center my-0 pb-3">
                    View an unprecedented library of election and demographic
                    data
                </p>
                <div className="mx-auto flex justify-center">
                    <a
                        className="text-blue-500 hover:text-blue-600 text-lg my-0 flex gap-2 p-2 transition-all duration-300"
                        href="https://docs.redistricter.com/data/data-list/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Browse the data library{" "}
                        <FiArrowRight className="h-auto w-[full] my-auto transition-all duration-300" />
                    </a>
                </div>
            </div>
            <div className="container mx-auto pb-4 px-5">
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-2">
                    {datasetsColumns.map((columnDatasets, index) => (
                        <div key={index}>
                            {columnDatasets.map((dataset: any) => (
                                <Dataset key={dataset.name} dataset={dataset} />
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            {/* <div className="sticky bottom-0 w-full h-12 bg-gradient-to-b from-transparent to-neutral-50" /> */}
        </div>
    );
}
