import React, { useState } from 'react';
// import { getFirebase } from "../lib/firebase";

export function AuthTest() {
    // const { auth } = getFirebase();
    const [subscriptionStatus, setSubscriptionStatus] = useState('Please enter a User ID to check the subscription status.');
    const [userInput, setUserInput] = useState('');
    const [isCommercial, setIsCommercial] = useState(false);
    const [userCount, setUserCount] = useState(1);

    // Define checkSubscriptionStatus as a function inside the component
    const checkSubscriptionStatus = async () => {
        if (!userInput) {
            setSubscriptionStatus('Please enter a User ID to check the subscription status.');
            return;
        }

        try {
            const requestBody = userInput.includes('@') ? { email: userInput } : { userId: userInput };
          
            // const response = await fetch('http://127.0.0.1:5001/redistricter/us-central1/getUserSubscriptionStatus', {
            const response = await fetch('https://us-central1-redistricter.cloudfunctions.net/getUserSubscriptionStatus', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                // Include Authorization header if needed
              },
              body: JSON.stringify(requestBody)
            });
          
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
          
            // Get the data from the response
            const data = await response.json();
            const subscriptions = data.subscriptions;
            console.log("Subscriptions: ", subscriptions);

            const role = data.role;
            setSubscriptionStatus(`User role: ${role}`);
          
            // Create an array to store the price IDs of active subscriptions
            // const activePriceIds = subscriptions
            //   .filter((subscription: any) => subscription.status === 'active')
            //   .map((subscription: any) => subscription.items.data[0].price.id); // Assuming each subscription has at least one item
          
            // let subscriptionStatus = 'No active subscription found.';
            // if (activePriceIds.length > 0) {
            //   subscriptionStatus = `Active subscription(s) found! Price ID(s): ${activePriceIds.join(', ')}`;
            // }
            // setSubscriptionStatus(subscriptionStatus);
            // console.log("Active price IDs: ", activePriceIds);
          } catch (error) {
            console.error('Error fetching subscription status:', error);
            setSubscriptionStatus('Error checking subscription status.');
          }
    };

    const handleInputChange = (event: any) => {
        setUserInput(event.target.value);
    };

    const handleSubmit = () => {
        // Call the function to check subscription status when user submits the form.
        checkSubscriptionStatus();
    };

    const createCheckoutSession = async () => {
        // Define custom parameters
        const customParams = {
            userId: userInput, // assuming `userInput` holds the user ID
            isCommercial: isCommercial,
            userCount: userCount,
            // Add other custom parameters here if needed
        };

        try {
            // const response = await fetch('http://127.0.0.1:5001/redistricter/us-central1/createCheckoutSession', {
            const response = await fetch('https://us-central1-redistricter.cloudfunctions.net/createCheckoutSession', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Include Authorization header if needed
                },
                body: JSON.stringify(customParams),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            // Redirect to Stripe Checkout using the session ID
            const sessionId = data.sessionId;
            window.location.href = `https://checkout.stripe.com/pay/${sessionId}`;
        } catch (error) {
            console.error('Error creating checkout session:', error);
            // Handle error by showing user feedback
        }
    };

    return (
        <div style={{ marginTop: 100 }}>
            <h3>Subscription Status</h3>
            <input
                type="text"
                value={userInput}
                onChange={handleInputChange}
                placeholder="Enter User ID"
            />
            <button onClick={handleSubmit}>Check Subscription</button>
            <p>{subscriptionStatus}</p>
            {/* Additional inputs for commercial option and user count */}
            <br />
            <label>
                <input
                    type="checkbox"
                    checked={isCommercial}
                    onChange={(e) => setIsCommercial(e.target.checked)}
                />
                Is Commercial
            </label>
            <input
                type="number"
                value={userCount}
                onChange={(e) => setUserCount(Number(e.target.value))}
                placeholder="Enter User Count"
                disabled={!isCommercial}
            />
            {/* Button to initiate checkout session */}
            <button onClick={createCheckoutSession}>Create Checkout Session</button>
        </div>
    );
}
