import React, { useState } from "react";
// @ts-ignore
import logo from "../assets/r3cropped.png";
// import styles from "../../components/mainHeader.module.css";

import { useAuthState } from "react-firebase-hooks/auth";
import { getFirebase } from "../lib/firebase";
import { FiMenu, FiX } from "react-icons/fi";
import { AccountDropdown } from "./accountDropdown";

const links: {
    href: string;
    label: string;
    showMobile: boolean;
}[] = [
    {
        href: "/#pricing",
        label: "Pricing",
        showMobile: true
    },
    {
        href: "https://docs.redistricter.com/",
        label: "Docs",
        showMobile: true
    },
    {
        href: "/map/OK/demo/blockgroups",
        label: "Demo",
        showMobile: false
    }
];

export default function Header() {
    const { auth } = getFirebase();
    const [user] = useAuthState(auth);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // Effect to toggle body overflow based on menu state and screen size
    // useEffect(() => {
    //     const handleResize = () => {
    //         if (window.innerWidth <= 640) { // Assuming 640px is your mobile breakpoint
    //             document.body.style.overflow = isMenuOpen ? 'hidden' : 'auto';
    //         } else {
    //             document.body.style.overflow = 'auto';
    //         }
    //     };

    //     // Add event listener
    //     window.addEventListener('resize', handleResize);

    //     // Call handler right away so state gets updated with initial window size
    //     handleResize();

    //     // Remove event listener on cleanup
    //     return () => window.removeEventListener('resize', handleResize);
    // }, [isMenuOpen]);

    return (
        <div className="bg-neutral-50 pt-3 pb-1 px-4 gap-2 h-12 sm:h-16 mb-1.5 z-50 relative">
            <div className="flex items-center w-full h-full">
                <div
                    className={`flex-shrink-0 rounded-[4px] h-full flex items-center sm:px-4 sm:hover:bg-neutral-200 transition-all duration-300 cursor-pointer ${
                        isMenuOpen && "pl-4"
                    }`}
                    onClick={() => (window.location.href = "/")}
                >
                    <a
                        className="flex items-center shrink-0"
                        href="/"
                        title={`Redistricter homepage`}
                    >
                        <img
                            src={logo}
                            alt={`Redistricter logo`}
                            className="w-6 mr-4"
                        />
                        <span
                            className={`font-extrabold text-lg transition-all duration-300 ${
                                isMenuOpen && "opacity-0 sm:opacity-100"
                            }`}
                        >
                            Redistricter
                        </span>
                        <span
                            className={`font-extrabold transition-all duration-300 text-[8px] mb-auto mt-1 ml-0.5 ${
                                isMenuOpen && "opacity-0 sm:opacity-100"
                            }`}
                        >
                            TM
                        </span>
                    </a>
                </div>
                <div className="flex-grow items-center w-full h-full gap-1 px-4 font-medium rounded-[4px] hidden sm:flex">
                    {links.map((link) => (
                        <div
                            key={link.href}
                            onClick={() => (window.location.href = link.href)}
                            className="hover:bg-neutral-200 h-full cursor-pointer transition-all duration-300 flex items-center justify-center px-3 rounded-[3px]"
                        >
                            {link.label}
                        </div>
                    ))}
                    {user && (
                        <div
                            onClick={() => (window.location.href = "/maps")}
                            className="hover:bg-neutral-200 h-full cursor-pointer transition-all duration-300 flex items-center justify-center px-3 rounded-[3px]"
                        >
                            Maps
                        </div>
                    )}
                </div>
                {user ? (
                    <div className="ml-2 hidden sm:flex">
                        <AccountDropdown mapHeaderCSS={true} />
                    </div>
                ) : (
                    <div className="whitespace-nowrap h-full gap-2 ml-auto md:ml-auto hidden sm:flex">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-semibold h-full px-4 rounded-[4px] transition-all duration-300 hidden md:block"
                            onClick={() => (window.location.href = "/signin")}
                        >
                            Sign In
                        </button>
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-semibold h-full px-4 rounded-[4px] transition-all duration-300"
                            onClick={() => (window.location.href = "/signup")}
                        >
                            Sign Up
                        </button>
                    </div>
                )}
                <div className="sm:hidden flex items-center ml-auto">
                    <button
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        className="p-2"
                    >
                        {isMenuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
                    </button>
                </div>
                {isMenuOpen && (
                    <div className="absolute bg-neutral-50 top-full left-0 w-full flex flex-col items-start sm:hidden py-6 px-4 text-xl font-semibold">
                        {links.map(
                            (link) =>
                                link.showMobile && (
                                    <a
                                        key={link.href}
                                        href={link.href}
                                        className="-ml-[1px] py-2 px-4"
                                    >
                                        {link.label}
                                    </a>
                                )
                        )}
                        {!user ? (
                            <div className="flex flex-col mt-8 gap-2 pl-4 text-base text-white">
                                {/* <button
                                    className="py-1 px-4 rounded bg-blue-600 hover:bg-blue-700"
                                    onClick={() =>
                                        (window.location.href = "/signin")
                                    }
                                >
                                    Sign In
                                </button> */}
                                <a
                                    className="py-1 px-4 rounded bg-blue-600 hover:bg-blue-700"
                                    href="/signup"
                                >
                                    Sign Up
                                </a>
                            </div>
                        ) : (
                            <>
                                <a href="/maps" className="-ml-[1px] py-2 px-4">
                                    Maps
                                </a>
                                <a
                                    href="/account"
                                    className="-ml-[1px] py-2 px-4"
                                >
                                    Account
                                </a>
                                <p
                                    className="-ml-[1px] py-2 px-4"
                                    onClick={() => auth.signOut()}
                                >
                                    Sign Out
                                </p>
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
