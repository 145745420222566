import React, { useLayoutEffect, useRef } from "react";
import { Checkbox, CloseButton } from "@mantine/core";
import { County } from "./MapBrowser";
import * as d3 from 'd3';

function getStateFipsPrefix(counties: County[]): string {
    if (counties.length === 0) {
        return '';
    }
    return counties[0].fips.slice(0, -3);
}

export const CountySelector = ({
    counties,
    selectedCounties,
    setSelectedCounties,
    setShowCountySelector,
    state
}: {
    counties: County[],
    selectedCounties: string[],
    setSelectedCounties: (value: string[]) => void,
    setShowCountySelector: (value: boolean) => void,
    state: string
}) => {
    const stateFipsPrefix = getStateFipsPrefix(counties);
    const svgRef = useRef<SVGSVGElement>(null);

    useLayoutEffect(() => {
        d3.xml(`../../../county-svgs/${state}counties.svg`).then((data: any) => {
            if (!svgRef.current) {
                console.warn("ref.current is null");
                return;
            }

            const node = d3.select(svgRef.current).node();
            if (!node) {
                console.warn("node is null");
                return;
            }

            // Append the SVG only if it hasn't been appended before
            if (svgRef.current.children.length === 0) {
                svgRef.current.appendChild(data.documentElement);
            }

            const svg = d3.select(svgRef.current).select('svg');
            svg.attr('width', '100%')
                .attr('height', '100%');

            svg.selectAll('path').each(function (d: any) {
                d3.select(this).attr('fill', 'lightgray');
            });

            svg.selectAll('path')
                .style('stroke', (d, i, nodes) => {
                    return 'black'
                })
                .style('stroke-width', 1)
                .style('fill', (d, i, nodes) => {
                    // return 'green'
                    const countyId = (nodes[i] as SVGPathElement).id;
                    return selectedCounties.includes(stateFipsPrefix + countyId) ? 'blue' : 'lightgray';
                })
                .on('click', function (event: any, d: any) {
                    const countyId = stateFipsPrefix + d3.select(this).attr('id');
                    if (selectedCounties.includes(countyId)) {
                        setSelectedCounties(selectedCounties.filter(id => id !== countyId));
                    } else {
                        setSelectedCounties([...selectedCounties, countyId]);
                    }
                });
        });
    }, [state, counties, selectedCounties, setSelectedCounties, stateFipsPrefix]);

    return (
        <div style={{
            position: 'absolute',
            top: 20,
            left: 20,
            width: 'calc(100% - 40px)',
            height: 'calc(100% - 40px)',
            backgroundColor: 'white',
            zIndex: 500,
            borderRadius: 10,
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
            overflow: 'hidden',
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: 10,
            }}>
                <h1 style={{ margin: 'auto' }}>County Filter</h1>
                <CloseButton size='lg' onClick={() => setShowCountySelector(false)} />
            </div>
            <div style={{
                display: 'flex',
                height: 'calc(100% - 40px)',
                overflow: 'auto',
            }}>
                {/* <div
                    style={{
                        top: 50,
                        left: 15,
                        backgroundColor: 'white',
                        padding: 3,
                        borderRadius: 5
                    }}>
                    <p>Click on counties to add or remove them from the filter.</p>
                </div> */}
                <div style={{
                    flex: 1,
                    // width: 'calc(100% - 60px)',
                    // height: 'calc(100% - 60px)',
                    padding: 20,
                    paddingTop: 0,
                }}>
                    <p>Click on counties to add or remove them from the filter.</p>
                    <p>When you're done click the close button to exit this dialog.</p>
                    <svg ref={svgRef} style={{
                        height: 'calc(100% - 80px)',
                        width: '100%',
                        maxHeight: '100%',
                        maxWidth: '100%',
                    }} />
                </div>
                <div style={{
                    width: '200px',
                    backgroundColor: 'lightgray',
                    overflow: 'auto',
                    paddingBottom: 20
                }}>
                    <h3 style={{
                        textAlign: 'center',
                        margin: 5
                    }}>Counties</h3>
                    {counties.map((county) => (
                        <Checkbox
                            key={county.fips}
                            label={county.name.replace('County', '')}
                            checked={selectedCounties.includes(county.fips)}
                            onChange={() => {
                                if (selectedCounties.includes(county.fips)) {
                                    setSelectedCounties(selectedCounties.filter(id => id !== county.fips));
                                } else {
                                    setSelectedCounties([...selectedCounties, county.fips]);
                                }
                            }}
                            style={{ paddingLeft: 5, paddingTop: 5 }}
                        >
                            {county.name.replace('County', '')}
                        </Checkbox>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CountySelector;