import React, { useState } from "react";
import styles from "./mainHeader.module.css";
import logo from "../assets/r3.png";
import { FiMenu, FiX } from "react-icons/fi";

import { AccountDropdown } from "./accountDropdown";
import { useAuthState } from "react-firebase-hooks/auth";
import { getFirebase } from "../lib/firebase";

export function MainHeader() {
    const { auth } = getFirebase();
    const [user] = useAuthState(auth);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className={styles.headerContainer}>
            <div className={styles.headerContent}>
                <a href="/">
                    <img className={styles.logo} src={logo} alt="logo" />
                    <div>
                        <h1 className={styles.logoText}>
                            Redistricter
                            <span className={styles.tm}>™</span>
                        </h1>
                    </div>
                </a>
                <div className={`${styles.links} ${styles.mobileHidden}`}>
                    {!user ? (
                        <>
                            <a href="/#pricing">Pricing</a>
                            <a href="/map/OK/demo">Demo</a>
                            <a href="https://docs.redistricter.com">Docs</a>
                        </>
                    ) : (
                        <>
                            <a href="/maps">Maps</a>
                            <a href="https://docs.redistricter.com">Docs</a>
                            {/* <a href="/account">Account</a> */}
                        </>
                    )}
                </div>
                <div className={`${styles.buttons} ${styles.mobileHidden}`}>
                    {user ? (
                        <div className={styles.accountDropdown}>
                            <AccountDropdown mapHeaderCSS={false} />
                        </div>
                    ) : (
                        <>
                            <button className={styles.button} onClick={() => (window.location.href = "/signin")}>
                                Sign In
                            </button>
                            <button className={styles.button} onClick={() => (window.location.href = "/signup")}>
                                Sign Up
                            </button>
                        </>
                    )}
                </div>
                <div className={`${styles.menu} ${styles.mobileVisible}`} onClick={toggleMenu}>
                    <div className={`${styles.burgermenu} ${isMenuOpen ? styles.rotate : ""}`}>
                        {isMenuOpen ? <FiX className={styles.icon} /> : <FiMenu className={styles.icon} />}
                    </div>
                </div>
            </div>
            {isMenuOpen && (
                <div className={styles.dropdownMenu}>
                    {!user ? (
                        <>
                            <a href="/#pricing" onClick={toggleMenu}>
                                Pricing
                            </a>
                            <a href="https://docs.redistricter.com">Docs</a>
                            <a href="/signin">Sign In</a>
                            <a href="/signup">Sign Up</a>
                        </>
                    ) : (
                        <>
                            <a href="https://docs.redistricter.com">Docs</a>
                            <a href="/maps">Maps</a>
                            <a href="/account">Account</a>
                        </>
                    )}
                </div>
            )}
        </div>
    );
}
