import React, {
	createContext,
	useContext,
	useEffect,
	useState,
	Suspense,
} from 'react';
import "./App.css";
import "./css/svg.css";
import "./css/tailwind.css";

import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import { MainHeader } from "./components/mainHeader";
import { SignUp } from "./components/SignUp";
import { SignIn } from "./components/SignIn";
import { MapBrowser } from "./mapui/mapBrowser/MapBrowser"; // Lazy loading this didn't reduce bundle sizes enough to matter (3.8mb vs 3.5mb)
import { MainFooter } from "./components/mainFooter.js";
// import Footer from "./components/Footer";
// import { LandingPage } from "./views/LandingPage";
import NewLandingPage from "./landing-page/LandingPage";
// import { Features } from "./views/Features";
import { AuthTest } from "./views/AuthTest";
import { Elections } from "./views/Elections";
import { Form } from "./views/Form";
import { Tos } from "./views/Tos.js";
// import { Guide } from "./views/Guide.js";
import { UkInfo } from "./views/UkInfo.js";
import { Quote } from "./views/Quote.js";
import { Account } from "./views/Account.js";
import { SignOutPage } from "./views/Signout.js";
import { About } from "./views/About.js";
import { Changelog } from "./views/Changelog";
import { OpenSharedMap } from "./views/OpenSharedMap";
import { Message } from "./components/message.js";
import { SwitchToYearly } from "./views/switchToYearly";
import { DataDictionary } from "./views/DataDictionary.js";
import Page404 from "./views/404";
// import MapUI from "./mapui";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import useUserRole from './hooks/useUserRole';
import ErrorBoundary from './ErrorBoundary';
// import * as Sentry from "@sentry/browser";

const MapUI = React.lazy(() => import('./mapui'));

const Error: React.FC = () => {
    const shouldThrowError = true;
    const triggerError = () => {
		if (shouldThrowError) {
			console.log('testing');
			console.error('testing error 1');
			// @ts-ignore
			throw new Error('Test error 2');
			// Sentry.captureException('Test error 2');
		}
    };

    return (
        <div style={{ backgroundColor: 'red', width: '100%', height: '30vh' }}>
            <button onClick={triggerError}>Trigger Error</button>
        </div>
    );
};

type User = {
	email: string;
	uid: string;
	emailVerified: boolean;
	any: any;
};

type StripeRole = false | 'IsAccount' | 'Individual' | 'Organization' | 'Commercial' | 'Admin'

interface AppProps {
	user?: User;
	stripeRole?: StripeRole;
}

interface AuthContextType {
	user: any;
	loading: boolean;
}

export const AuthContext = createContext<AuthContextType>({ user: null, loading: true });

const useAuth = () => {
	return useContext(AuthContext);
};

// AuthProvider component to provide the auth state
const AuthProvider = (
	{ children }:
		{ children: React.ReactNode }
) => {
	const [user, setUser] = useState<any>(null);
	const [loading, setLoading] = useState(true);
	const auth = getAuth();

	useEffect(() => {
		// Listener for Firebase auth state
		const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
			setUser(currentUser);
			// if (currentUser?.email && currentUser?.uid) {
			// 	Sentry.setUser({ ip_address: "{{auto}}", email: currentUser.email, id: currentUser.uid });
			// 	Sentry.setTag("email", currentUser.email);
			// 	Sentry.setTag("uid", currentUser.uid);
			// } else {
			// 	Sentry.setUser(null);
			// }
			setLoading(false);
		});
		return unsubscribe; // Unsubscribe on unmount
	}, [auth]);

	// Render children immediately, without waiting for auth state
	return (
		<AuthContext.Provider value={{ user, loading }}>
			{children}
		</AuthContext.Provider>
	);
};

const PrivateRoute: React.FC<{ element: React.ReactElement, roleRequired: StripeRole }> = ({ element, roleRequired }) => {
	const { user, loading: userLoading } = useAuth();
	const { role, subscriptions, loading: roleLoading, error } = useUserRole(user?.uid, user?.email);
	// const { role, subscriptions, loading: roleLoading, error } = useUserRole("xHoqJOn9CzL3xWhBJ4zx3XxheOG3", "tjones.disd@gmail.com");
	console.log(user?.uid, user?.email, role, subscriptions, roleLoading, error);

	// if ((window as any).Verisoul && user?.email) {
	// 	(async () => {
	// 		await (window as any).Verisoul.account({
	// 			id: user.uid,
	// 			email: user.email,
	// 			// role: role,
	// 		});
	// 	})();
	// }

	if (!user && !userLoading) {
		return <Navigate to="/signup" />;
	}

	if (userLoading || (roleRequired && roleLoading)) {
		console.log("loading...");
		return (
			<div style={{ marginTop: 120, opacity: 0, animation: "fadein 2s", animationDelay: "2s", animationFillMode: "forwards" }}>
				Loading...
			</div>
		);
	}

	if (!roleRequired) {
		return element;
	}

	if (
		roleRequired === role
		|| (roleRequired === "Organization" && role === "Commercial")
		|| (roleRequired === "Individual" && (role === "Commercial" || role === "Organization"))
		|| roleRequired === 'IsAccount'
		|| role === 'Admin'
	) {
		return element;
	} else {
		return <Navigate to="/account" />;
	}
};

const RouteWithHeaderAndFooter: React.FC<{ element: React.ReactElement, hasHeader: boolean, hasFooter: boolean }> = ({ element, hasHeader, hasFooter }) => (
	<>
		{hasHeader && <MainHeader />}
		{element}
		{hasFooter && <MainFooter />}
	</>
);

const createRoute = (
	path: string,
	component: React.ReactElement,
	isPrivate: false | StripeRole = false,
	hasHeaderAndFooter: [boolean, boolean] = [true, true]
) => {
	let element = component;
	let [hasHeader, hasFooter] = hasHeaderAndFooter;

	if (hasHeader || hasFooter) {
		element = <RouteWithHeaderAndFooter element={component} hasHeader={hasHeader} hasFooter={hasFooter} />;
	}

	if (isPrivate && path !== "/form" && path !== "/switchToYearly") {
		// element = <PrivateRoute element={element} roleRequired={isPrivate} />;
		element =
			<>
				<Message />
				<PrivateRoute element={element} roleRequired={isPrivate} />
			</>;
	}

	// if (isPrivate === 'IsAccount') {
	// 	element = <PrivateRoute element={element} role={isPrivate} />;
	// } else if (isPrivate === 'Individual') {
	// 	element = <PrivateRoute element={element} />;
	// }

	return <Route key={path} path={path} element={element} />;
};

const mapUiWithSuspense = <Suspense fallback={<div style={{ color: 'white' }}>Loading javascript for map...</div>}><MapUI /></Suspense>;

const App: React.FC<AppProps> = () => {
	const routes = [
		// Public Routes
		// createRoute("/", <LandingPage />),
		createRoute("/", <NewLandingPage />, false, [false, false]),
		createRoute("/signin", <SignIn />, false, [true, false]),
		createRoute("/signup", <SignUp />, false, [true, false]),
		createRoute("/signup/commercial", <SignUp />, false, [true, false]),
		createRoute("/signup/commercial/yearly", <SignUp />, false, [true, false]),
		createRoute("/signup/individual", <SignUp />, false, [true, false]),
		createRoute("/signup/individual/yearly", <SignUp />, false, [true, false]),
		createRoute("/signup/individual/yearly", <SignUp />, false, [true, false]),
		// createRoute("/features", <Features />),
		createRoute("/elections", <Elections />),
		createRoute("/tos", <Tos />),
		createRoute("/datadictionary", <DataDictionary />),
		createRoute("/about", <About />),
		// createRoute("/guide", <Guide />),
		createRoute("/uk", <UkInfo />),
		createRoute("/quote", <Quote />, false, [true, false]),
		createRoute("/signout", <SignOutPage />, false, [false, false]),
		createRoute("/switchToYearly", <SwitchToYearly />, 'Individual', [true, false]),
		// createRoute("/map/OK/demo/", <MapUI />, false, [false, false]),
		// createRoute("/map/OK/demo/blockgroups", <MapUI />, false, [false, false]),
		createRoute("/map/OK/demo/", mapUiWithSuspense, false, [false, false]),
		createRoute("/map/OK/demo/blockgroups", mapUiWithSuspense, false, [false, false]),
		// Private Routes
		// createRoute("/maps", <MapBrowser />, 'Individual', [false, false]),
		// createRoute("/map/:state", <MapUI />, 'Individual', [false, false]),
		// createRoute("/map/:state/:mapId?/:shape?", <MapUI/>, 'Individual', [false, false]),
		createRoute("/maps", <MapBrowser />, 'Individual', [false, false]),
		createRoute("/map/:state", mapUiWithSuspense, 'Individual', [false, false]),
		createRoute("/map/:state/:mapId?/:shape?", mapUiWithSuspense, 'Individual', [false, false]),
		createRoute("/authTest", <AuthTest />, 'Admin'),
		createRoute("/account", <Account />, 'IsAccount'),
		createRoute("/form", <Form />, 'Individual'),
		createRoute("/changelog", <Changelog />),
		createRoute("/share/:mapId", <OpenSharedMap />, 'Individual', [false, false]),
		createRoute("/error", <Error />, 'Individual', [false, false]),
	];

	return (
		<div className="App">
			<AuthProvider>
				<Router>
					<section>
						<ErrorBoundary>
							<Routes>
								{routes}
								<Route path="*" element={<Page404 />} />
							</Routes>
						</ErrorBoundary>
					</section>
				</Router>
			</AuthProvider>
		</div>
	);
}

export default App;