import {
	collection,
	getDocs,
} from "firebase/firestore";
import { getFirebase, getCurrentUser } from '../../lib/firebase';

export async function fetchMapsList(): Promise<any[]> {
	const userId = getCurrentUser()?.uid;
	const { firestore } = getFirebase();

	if (!userId) {
		console.error("Won't fetch maps list, no user signed in");
		return [];
	}

	const mapsRef = collection(firestore, "users", userId, "maps");
	const mapSnapshots = await getDocs(mapsRef);

	const mapsToReturn = mapSnapshots.docs.map((doc) => ({
		id: doc.id,
		...doc.data()
	}));

	return mapsToReturn;
}