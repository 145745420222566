import {
	getDownloadURL,
	getStorage,
	ref,
	uploadString,
	deleteObject
} from "firebase/storage";
import {
	doc,
	setDoc,
	collection,
	getDocs,
	deleteDoc,
	query,
	where,
	limitToLast,
	orderBy,
	getDoc,
} from "firebase/firestore";
import { v4 } from "uuid";
import { getFirebase, signedIn, getCurrentUser } from '../../lib/firebase';
import { ObjectHash } from '../../lib/types';
import { Feature } from '../types';
import { DistrictsSettings } from "../context/mapContextConfig";
// import { PrecinctInCityType } from "./getPrecinctsInCity";

// no type defs available so can't import
const pako = require("pako");
const shp = require("shpjs");

// const API_BASE_URL = 'http://127.0.0.1:5001/redistricter/us-central1';
const API_BASE_URL = 'https://us-central1-redistricter.cloudfunctions.net';
// const DATA_BASE_URL = 'https://f005.backblazeb2.com';

// const DATA_BASE_URL = 'https://redistricter-precincts.s3.us-east-2.amazonaws.com';
const DATA_BASE_URL = 'https://data.redistricter.com';
const MAX_UPLOADS_PER_DAY = 50;

type precinctToSplitType = {
	CNTYVTD: string;
	COUNTYFP: string;
};

export interface GeoJSON {
	type: string,
	features: {
		type: string,
		properties: { id: string },
		geometry: any
	}[]
}

export interface BlockData {
	features: Feature[];
	type?: string;
}

export interface CombinedData {
	precincts: ObjectHash,
	counties: ObjectHash,
	cities: ObjectHash
	error?: Error;
}

const shpToGeoJSON = async (buffer: any): Promise<GeoJSON | null> => {
	try {
		const geometries = await shp.parseShp(buffer);
		const features = geometries.map((geometry: any, id: string) => {
			return {
				type: "Feature",
				properties: { id },
				geometry
			};
		});
		return {
			type: "FeatureCollection",
			features
		};
	} catch (error) {
		console.error("Error converting shp to GeoJSON:", error);
		return null;
	}
}

export const fetchPopStatsForCounty = async (state: string, countyFP: string | string[]): Promise<ObjectHash | null> => {
	try {
		const countyFPs = Array.isArray(countyFP) ? countyFP : [countyFP];
		const combinedStats: ObjectHash = {};
		for (const county of countyFPs) {
			const countyCode = county.toString().slice(-3);
			const mapRefId = `countymaps/${state}/${countyCode}all.json`;
			console.log(`Loading map by ref ${mapRefId}`);
			const mapRef = ref(getStorage(), mapRefId);
			const url = await getDownloadURL(mapRef);
			const responseFile = await fetch(url);
			const responseJSON = await responseFile.json();

			const countyStats = responseJSON[0];
			// Combine the population stats
			for (const key in countyStats) {
				const value = parseFloat(countyStats[key]);
				if (combinedStats[key]) {
					combinedStats[key] += value;
				} else {
					combinedStats[key] = value;
				}
			}
		}
		return [combinedStats];
	} catch (error) {
		console.error(error);
		return null;
	}
}

export const fetchData = async (state: string, type: string, countyFilter: any): Promise<ObjectHash | null> => {
	// @todo remove the need for this check
	if (state === undefined || type === undefined) {
		return null;
	}

	let url: string | undefined = undefined, responseFile: any;
	const responses: any[] = [];

	if (!countyFilter || countyFilter.current === null) {
		if (state === "UK") {
			if (type === "BlockGroups") {
				url = `${DATA_BASE_URL}/UKMidOutputAreas.geojson.gz`;
			} else if (type === "precincts") {
				url = `${DATA_BASE_URL}/UKLowOutputAreas.geojson.gz`;
			} else {
				url = `${DATA_BASE_URL}/UKCounties.geojson.gz`;
				// url = `/2DEcounties.geojson.gz`;
			}
		} else {
			url = `${DATA_BASE_URL}/2${state}${type}.geojson.gz`;
		}

		responseFile = await fetch(url, {
			headers: {
				"Accept-Encoding": "gzip"
			}
		});
		responses.push(responseFile);
	} else {
		const shapeTypeID = type === "BlockGroups" ? "bg" : "precincts";

		const countyFilters = Array.isArray(countyFilter) ? countyFilter : [countyFilter];
		for (let filter of countyFilters) {
			let countyFilterCorrect: boolean;
			if (filter.current) {
				countyFilterCorrect = filter.current;
			} else {
				countyFilterCorrect = filter;
			}

			const countyCode = countyFilterCorrect.toString().slice(-3);
			const mapRefId = `countymaps/${state}/${countyCode}_${shapeTypeID}.geojson.gz`;
			console.log(`Loading map by ref ${mapRefId}`);
			const mapRef = ref(getStorage(), mapRefId);
			try {
				const url = await getDownloadURL(mapRef);
				responseFile = await fetch(url);
				responses.push(responseFile);
			} catch (error) {
				console.error(error);
				return null;
			}
		}
	}

	if (responses.length === 0) {
		console.log("no response file found. url: ", url);
		return null;
	}

	const allFeatures: any[] = [];
	for (let response of responses) {
		const arrayBuffer = await response.arrayBuffer();
		const encryptedData = new Uint8Array(arrayBuffer);

		const keyString = `${state}${process.env.REACT_APP_DATA_TOKEN}`;
		const encoder = new TextEncoder();
		const keyBytes = encoder.encode(keyString);

		const decryptedData = new Uint8Array(encryptedData.length);
		for (let i = 0; i < encryptedData.length; i++) {
			decryptedData[i] = encryptedData[i] ^ keyBytes[i % keyBytes.length];
		}

		const decompressedData = pako.inflate(decryptedData.buffer, {
			to: "string"
		});

		const geojson = JSON.parse(decompressedData);
		allFeatures.push(...geojson.features);
	}

	return {
		type: "FeatureCollection",
		features: allFeatures
	};
}

export const fetchDataCombined = async (
	shapeType: string,
	states: string[],
	countyFilter: any,
	// isUK: boolean
): Promise<CombinedData> => {
	let shapeTypeFileName = shapeType;
	if (shapeType === "blockgroups") {
		shapeTypeFileName = "BlockGroups";
	}

	const statesDataList = states;

	const stateDataPromises = statesDataList.map((state) =>
		Promise.all([
			fetchData(
				state,
				shapeTypeFileName,
				countyFilter
			),
			fetchData(state, "counties", null),
			fetchData(state, "cities", null)
		])
	);

	let stateData;

	try {
		stateData = await Promise.all(stateDataPromises);
	} catch (error: any) {
		console.error(error);
		return { precincts: {}, counties: {}, cities: {}, error };
	}

	if (statesDataList!.length === 1) {
		return {
			precincts: {
				type: "FeatureCollection",
				features: stateData.flatMap((data) => data[0]?.features)
			},
			counties: {
				type: "FeatureCollection",
				features: stateData.flatMap((data) => data[1]?.features)
			},
			cities: {
				type: "FeatureCollection",
				features: stateData.flatMap((data) => data[2]?.features)
			}
		};
	}

	return {
		precincts: {
			type: "FeatureCollection",
			features: stateData.flatMap((data, index) => {
				return (data[0]?.features || []).map((feature: Feature) => ({
					...feature,
					properties: {
						...feature.properties,
						CNTYVTD:
							statesDataList![index] + feature.properties.CNTYVTD,
						COUNTYFP:
							statesDataList![index] + feature.properties.COUNTYFP
					}
				}));
			})
		},
		counties: {
			type: "FeatureCollection",
			features: stateData.flatMap((data, index) => {
				return (data[1]?.features || []).map((feature: Feature) => ({
					...feature,
					properties: {
						...feature.properties,
						COUNTYFP:
							statesDataList![index] + feature.properties.COUNTYFP
					}
				}));
			})
		},
		cities: {
			type: "FeatureCollection",
			features: stateData.flatMap((data) => data[2]?.features)
		}
	};
}

export type SplitPrecinctsReturnType = {
	CNTYVTD: string;
	data: BlockData;
};

export const fetchBlocks = async (
	state: string,
	countyFP: string,
	CNTYVTD: string,
	shapeType: string,
	isMultiState: boolean
): Promise<BlockData | null> => {
	countyFP = countyFP.toString();

	let newCNTYVTD = CNTYVTD;
	if (isMultiState) {
		newCNTYVTD = CNTYVTD.substring(2);
		countyFP = countyFP.substring(2);
	}

	if (countyFP.length === 2) {
		countyFP = "0" + countyFP;
	} else if (countyFP.length === 1) {
		countyFP = "00" + countyFP;
	}

	const safeCNTYVTD = newCNTYVTD.replace(/,/g, '-');
	const precinctsToSplit: precinctToSplitType[] = [{ CNTYVTD: safeCNTYVTD, COUNTYFP: countyFP }];

	const body = JSON.stringify({ state, precinctsToSplit, shapeType });

	try {
		const response = await fetch(
			`${API_BASE_URL}/getBlockData`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: body
			}
		);
		const results: SplitPrecinctsReturnType[] = await response.json();

		if (isMultiState) {
			results[0].CNTYVTD = CNTYVTD;
			results[0].data.features.forEach((feature) => {
				feature.properties.CNTYVTD = CNTYVTD;
			});
		}

		return results[0].data;
	} catch (error) {
		console.error("Error processing get blocks in shape:", error);
		return null;
	}
}

// export const getBlocksInCity = async (
//   state: string,
//   cityID: string,
//   shapeType: string,
//   partiallyInCity: PrecinctInCityType[]
// ): Promise<SplitPrecinctsReturnType[] | null> => {
//   const body = JSON.stringify({ state, cityID, shapeType, partiallyInCity });

//   try {
//     const response = await fetch(
//       `${API_BASE_URL}/getBlocksInCity`,
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json"
//         },
//         body: body
//       }
//     );
//     const results: SplitPrecinctsReturnType[] = await response.json();
//     // console.log("results", results);
//     return results;
//   } catch (error) {
//     console.error("Error processing get blocks in shape:", error);
//     return null;
//   }
// }

// export const processDistrictMap = async (geojson: GeoJSON, state: string, settings: any): Promise<string | null> => {
//   try {
//     const response = await fetch(
//       `${API_BASE_URL}/processDistrictMap`,
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json"
//         },
//         body: JSON.stringify({ geojson, state, settings })
//       }
//     );
//     const responseText = await response.text();
//     if (responseText.startsWith('{"type": "FeatureCollection"')) {
//       console.error("Expected SVG, but got GeoJSON response");
//       return null;
//     }
//     return await response.text();
//   } catch (error) {
//     console.error("Error processing district map:", error);
//     return null;
//   }
// }

export const importMap = async (
	inputMap: any,
	state: string,
	filetype: string,
	shape: any,
	splitPrecincts: boolean
): Promise<ObjectHash | string> => {
	const userId = getCurrentUser()?.uid;

	if (!userId) {
		console.error("Won't upload, no user signed in");
		return "error";
	}

	const { firestore } = getFirebase();

	const date = new Date();
	const dateString = date.toISOString();

	const docRef = doc(firestore, "users", userId, "uploads", dateString);

	const oneDayAgo = new Date();
	oneDayAgo.setDate(oneDayAgo.getDate() - 1);
	const oneDayAgoString = oneDayAgo.toISOString();

	const uploadsRef = collection(firestore, "users", userId, "uploads");
	const q = query(
		uploadsRef,
		where("dateString", ">=", oneDayAgoString),
		orderBy("dateString", "desc"),
		limitToLast(500)
	);
	const querySnapshot = await getDocs(q);

	console.log(
		"Number of uploads in the last 24 hours: ",
		querySnapshot.docs.length
	);

	if (querySnapshot.docs.length > MAX_UPLOADS_PER_DAY) {
		console.warn("Too many uploads in the last 24 hours, aborting");
		return {
			error: `Exceeded daily limit of map uploads (${MAX_UPLOADS_PER_DAY} uploads/day)`
		};
	}

	let body;
	if (filetype === "shp") {
		const geojson = await shpToGeoJSON(inputMap);

		if (geojson) {
			body = JSON.stringify({ geojson, state, filetype, shape, userId });
		} else {
			console.warn("Error converting shp to GeoJSON");
			return "error";
		}
	} else {
		// outputMap = inputMap;
		body = JSON.stringify({ geojson: inputMap, state, filetype, shape, userId });
	}

	const functionToUse = splitPrecincts ? "importMap2" : "importMap";

	try {
		const response = await fetch(
			`${API_BASE_URL}/${functionToUse}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: body
			}
		);
		const results = await response.json();
		// console.log("results: ", results);

		if (!response.ok || results.error) {
			throw new Error(results.error || "An error occurred during the import.");
		}

		// Log upload to Firestore
		await setDoc(docRef, { dateString: dateString }, { merge: true });
		return results;
	} catch (error) {
		console.error("Error processing district map:", error);
		return { error: (error as any).message || "An unknown error occurred." };
	}
}

export const saveMap = async (mapData: ObjectHash, mapId: string): Promise<string | null> => {
	const userId = getCurrentUser()?.uid;
	const { firestore } = getFirebase();

	if (!userId) {
		console.error("Won't save, no user signed in");
		return null;
	}

	// So the map will save to the users maps
	if (mapId.startsWith('PRE_')) {
		mapId = mapId.slice(4);
	}

	// mapId = `PRE_${mapId}`;

	// Create a reference to the file we want to upload
	const mapRef = ref(getStorage(), `users/${userId}/${mapId}`);
	// const mapRef = ref(getStorage(), `preMadeMaps/${mapId}`);

	try {
		// Upload the file to Firebase Storage
		console.log("Uploading map to Firebase Storage", userId, mapId);
		await uploadString(mapRef, JSON.stringify(mapData));
		const docRef = doc(firestore, "users", userId, "maps", mapId);
		const mapInfo = mapData.StorageMapInfo;
		const mapImage = mapData.StorageMapImage;
		const mapCountyFilter = mapData.StorageMapCountyFilter;

		// Save each field in StorageMapInfo as a separate Firestore field
		const savePromises = Object.keys(mapInfo).map((key) =>
			setDoc(docRef, { [key]: mapInfo[key] }, { merge: true })
		);

		// Save the mapImage
		savePromises.push(setDoc(docRef, { mapImage: mapImage, mapCountyFilter: mapCountyFilter }, { merge: true }));

		// Add the promise to save the current date and time
		savePromises.push(setDoc(docRef, { date: new Date() }, { merge: true }));

		// Wait for all save operations to complete
		await Promise.all(savePromises);

		console.log("Map saved with ID:", mapId);
		return mapId;
	} catch (error) {
		console.error("Error saving map:", error);
		return null;
	}
}


export const loadMap = async (
	mapId: string | null,
	sharedUserId?: string,
	sharedMapId?: string
): Promise<ObjectHash | null> => {
	let userId = getCurrentUser()?.uid;

	if (sharedUserId && sharedMapId) {
		userId = sharedUserId;
		mapId = sharedMapId;
	}

	if (!userId) {
		console.error("Won't load, no user signed in");
		return null;
	}

	if (!mapId) {
		console.error("Won't load, no map ID provided");
		return null;
	}

	const isPreMadeMap = mapId.startsWith('PRE_');

	// Create a reference to the file we want to download
	const mapRefId = isPreMadeMap ? `preMadeMaps/${mapId}` : `users/${userId}/${mapId}`;
	// console.log(`Loading map by ref ${mapRefId}`);    
	const mapRef = ref(getStorage(), mapRefId);

	try {
		// Get download URL for the file
		const url = await getDownloadURL(mapRef);

		// Fetch the file from the download URL
		const response = await fetch(url);

		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		// Parse the downloaded file as JSON
		const mapData = await response.json();

		console.log(`Map loaded with ID <${mapId}>`);
		return mapData;
	} catch (error) {
		console.error(`Error loading map <${mapId}>: `, error);
		return null;
	}
}

export const deleteMap = async (mapId: string): Promise<{ file: boolean, doc: boolean }> => {
	const userId = getCurrentUser()?.uid;
	const response = { file: false, doc: false };
	const { firestore } = getFirebase();

	if (!userId) {
		console.error("Won't delete map, no user signed in");
		return response;
	}

	if (!mapId) {
		console.error("Won't delete map, no map id specified");
		return response;
	}

	// Create a reference to the file we want to delete
	const mapRef = ref(getStorage(), `users/${userId}/${mapId}`);

	try {
		console.log("Deleting map from Firebase Storage", userId, mapId, mapRef);
		await deleteObject(mapRef);
		response.file = true;
	} catch (err: any) {
		// Handle specific error for file not found in Firebase Storage
		if (err.code === "storage/object-not-found") {
			console.warn("Map file not found in Firebase Storage");
			console.error(err);
		} else {
			console.error("Error deleting map from Firebase Storage");
			console.error(err);
			return response;
		}
	}

	// Proceed to delete the metadata from Firestore irrespective of Firebase Storage status
	try {
		const docRef = doc(firestore, "users", userId, "maps", mapId);
		await deleteDoc(docRef);
		response.doc = true;
		console.log(`Map metadata deleted with ID <${mapId}>`);
	} catch (err) {
		console.error("Error deleting map metadata from Firestore");
		console.error(err);
	}

	return response;
}

export const duplicateMap = async (mapId: string): Promise<string | null> => {
	if (!signedIn()) {
		console.error("Won't duplicate, no user signed in");
		return null;
	}

	if (!mapId) {
		console.error("Won't duplicate, no no map ID provided");
		return null;
	}

	try {
		// Fetch the existing map data
		const mapData = await loadMap(mapId);

		if (!mapData) {
			throw new Error('Unable to load map');
		}

		// Generate a new ID for the duplicate map
		const newMapId = v4(); // Using uuid.v4() to generate a new ID

		// Save the map data under the new ID
		const result = await saveMap(mapData, newMapId);

		if (!result) {
			throw new Error('Unable to save map');
		}

		console.log("Map duplicated with new ID:", newMapId);
		return newMapId;
	} catch (err) {
		console.error("Error duplicating map");
		console.error(err);
		return null;
	}
}

export async function shareMapLink(
	mapId: string,
	districts: DistrictsSettings,
	shapeType: string
): Promise<string> {
	const userId = getCurrentUser()?.uid;
	const { firestore } = getFirebase();

	const sharedMapRef = doc(firestore, "sharedMaps", mapId);

	// Check if the document already exists
	const docSnap = await getDoc(sharedMapRef);
	if (!docSnap.exists()) {
		await setDoc(sharedMapRef, {
			mapId,
			userId,
			states: districts.states,
			countyFilter: districts.countyFilter || null,
			shapeType: shapeType,
			date: new Date()
		});
	} else {
		console.log(`Document with mapId ${mapId} already exists.`);
	}

	return Promise.resolve(`https://redistricter.com/share/${mapId}`);
}