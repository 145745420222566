import { useState, useEffect } from 'react';

// Last checked August 10
const individualEmails = [
    "shahar9261@gmail.com",
    "tjones.disd@gmail.com",
    "alrobbie12@gmail.com", // Email with payment is alrobbie12@gmai.com
    "vojtamikulecky5@gmail.com", // Signed up with wrong email. I changed it to the correct one but Stripe still doesn't recognize it :/
    
    "colin.d.miller@icloud.com",

    // Free trial accounts
    // "chrismaps146@gmail.com", // 2024-05-12
    // "drewdeberry@gmail.com", // 2024-05-19
    // "brandon@protomaps.com", // 2024-06-12
    // "Cooper.Burton@abc.com", // 2024-06-18
    // "nathaniel.rakich@fivethirtyeight.com", // 2024-06-18
    // "politicaltracker4096@gmail.com", // 2024-07-13 (for commercial data glitch)
    // "michael.li@nyu.edu", // 2024-07-19
    // "sgerontakis@ufl.edu", // 2024-07-25
    // "bwalker@brentwood.com", // 2024-07-29
    "thecitynerd@nebula.tv", // 2024-08-29
    "spojman123@gmail.com", // 2024-08-31
    "Adi.Texas@gmail.com", // 2024-10-19  
    "ikaufer327@gmail.com", // 2025-04-25 (for PA 2022/2023 data)
    
    // Free accounts
    "gavinwillsey@hotmail.com",
    "jace@jrand.com",
    "abhinavmadamanchi@gmail.com",

    // Test accounts:
    "vehagot587@cwtaa.com",
    "pasad98821@kxgif.com",
    // "jejeget991@pbridal.com"
];

// Check again for renewal in February 2025
const organizationEmails = [
    "patrick@echeloninsights.com",
    "ryan.doogan@echeloninsights.com",
    "jonathan.detroye@echeloninsights.com",

    "quoctrung.bui@nytimes.com", // 2024-11-02
    "jeremy.ashkenas@nytimes.com", // 2024-11-02
    "jesse.wegman@nytimes.com", // 2024-11-02
    "mbloch@nytimes.com", // 2024-11-02
    "ykharrati@gmail.com", // Goofball who somehow locked himself out of the paying email :/ actual email is ykharrati02@gmail.com
    // "colin@redistricter.com"

    "data@publicmedia.com", // Subscription email is dan@publicmedia.com
];

const adminEmails = [
    "colin@redistricter.com",
    "dorothy.x.ren@gmail.com"
    // "ss"
];

const useUserRole = (userUID: string, userEmail: string) => {
    // const [role, setRole] = useState<string | null>(null);
    const [role, setRole] = useState<string | null>(getRoleFromSessionStorage());
    const [subscriptions, setSubscriptions] = useState<string[] | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    function getRoleFromSessionStorage() {
        const data = sessionStorage.getItem('userRole');
        if (!data) return null;

        const parsedData = JSON.parse(data);
        const now = new Date().getTime();
        const oneHour = 1000; // in milliseconds

        if (now - parsedData.timestamp > oneHour) {
            // If the data is older than an hour, remove it from sessionStorage
            sessionStorage.removeItem('userRole');
            return null;
        }

        return parsedData.role;
    }

    useEffect(() => {
        // console.log("role before fetch user role ", role);
        // console.log("session storage ", sessionStorage.getItem('userRole'));

        const fetchUserRole = async () => {
            // console.log('fetchUserRole');

            if (organizationEmails.includes(userEmail)) {
                setRole('Organization');
                setLoading(false);
                return;
            } else if (individualEmails.includes(userEmail)) {
                setRole('Individual');
                setLoading(false);
                return;
            } else if (adminEmails.includes(userEmail)) {
                setRole('Admin');
                // setRole('Organization')
                setLoading(false);
                return;
            }

            try {
                setLoading(true);
                // console.log('fetchUserRole');
                const requestBody = { userId: userUID };
                const response = await fetch('https://us-central1-redistricter.cloudfunctions.net/getUserSubscriptionStatus', {
                    // const response = await fetch('http://127.0.0.1:5001/redistricter/us-central1/getUserSubscriptionStatus', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        // Include Authorization header if needed
                    },
                    body: JSON.stringify(requestBody)
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setSubscriptions(data.subscriptions);
                setRole(data.role);
            } catch (error: any) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        if (userUID) {
            fetchUserRole();

            if (role) {
                setLoading(false);
                // console.log("setting session storage ", role);
                sessionStorage.setItem('userRole', JSON.stringify({ role: role, timestamp: new Date().getTime() }));
            }

        }
    }, [role, userEmail, userUID]);

    // console.log("role after fetch user role ", role);

    return { role, subscriptions, loading, error };
};

export default useUserRole;