import React, { useCallback, useEffect, useState } from 'react';
import { doc, getDoc, getFirestore, DocumentData } from 'firebase/firestore'; 
import { useNavigate } from "react-router-dom";

export const OpenSharedMap: React.FC = () => {
    // If possible, replace 'any' with a more specific type that matches your map details structure
    const [mapDetails, setMapDetails] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const mapId = window.location.href.split("/").pop();
    const navigate = useNavigate();

    const loadMap = useCallback((mapDetails: any) => {
        let newMapData;

        if (mapDetails.countyFilter) {
            newMapData = {
                countyFilter: mapDetails.countyFilter,
                sharedUserId: mapDetails.userId,
            };
        } else {
            newMapData = {
                sharedUserId: mapDetails.userId,
                sharedMapId: mapDetails.mapId,
            };
        }
    
        // convert states arrary to string
        const states = mapDetails.states.join("");
    
        if (mapDetails.shapeType && mapDetails.shape !== "precincts") {
            // navigate(`/map/${states}/${mapDetails.mapId}/${mapDetails.shapeType}`, { state: newMapData });
            navigate(`/map/${states}/${mapDetails.shapeType}`, { state: newMapData });
        } else {
            navigate(`/map/${states}/`, { state: newMapData });
        }
    }, [navigate]); 

    // console.log(loadMap);

    useEffect(() => {
        const fetchMapDetails = async () => {
            if (!mapId) return;
    
            const firestore = getFirestore();
            const docRef = doc(firestore, "sharedMaps", mapId);
            try {
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    // Directly setting the document data without type mismatch
                    const fetchedMapDetails = docSnap.data() as DocumentData;
                    setMapDetails(fetchedMapDetails);
                    loadMap(fetchedMapDetails); // Call loadMap here with the fetched details
                } else {
                    console.log("No such document!");
                    // Handle the case where the document does not exist
                }
            } catch (error) {
                console.error("Error fetching shared map details:", error);
                // Handle error (e.g., show an error message)
            } finally {
                setLoading(false);
            }
        };
    
        fetchMapDetails();
    }, [mapId, loadMap]);

    if (loading) {
        return <p style={{ padding: 20 }}>Loading shared map...</p>;
    }

    if (!mapDetails) {
        return <p style={{ padding: 20 }}>Failed to load shared map details.</p>;
    }

    return (
        <div style={{ padding: 20 }}>
            {/* Render your map details here using mapDetails */}
            <p>Map Details: {JSON.stringify(mapDetails)}</p>
        </div>
    );
};