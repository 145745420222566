import React from "react";
import { MainHeader } from "../components/mainHeader";

export default function Page404() {
	return (
		<>
			<MainHeader />
		<h1
			style={{
				marginTop: "100px",
				textAlign: "center",
				fontSize: "2rem"
			}}
		>
			404 Not Found
		</h1>
	</>
);
}