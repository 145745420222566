import React, { useState } from "react";
import { Paper, TextInput, Button, Text, Group } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import "firebase/compat/firestore";
import { setDoc, doc } from "firebase/firestore";
import { getFirebase } from "../lib/firebase";

export function Quote() {
  const isMobile = useMediaQuery("(max-width: 50em)");
  const [active, setActive] = useState(0);
  const [error, setError] = useState("");
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    organizationName: "",
    data: "",
    use: ""
  });

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firestore: db } = getFirebase();

    try {
      const randomNumber = Math.floor(Math.random() * 1000).toFixed(0);
      await setDoc(doc(db, "quotes", formState.email + " #" + randomNumber), {
        organizationName: formState.organizationName,
        email: formState.email,
        timestamp: new Date().toLocaleString("en-US", {
          timeZone: "America/Chicago"
        })
      });

      await fetch(
        "https://us-central1-redistricter.cloudfunctions.net/sendEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(formState)
        }
      )
        .then(() => {
          // console.log("cloud server response: ", res);
        })
        .catch(() => {
          // console.log("cloud server error: ", err);
        });

      setActive(1);
    } catch (e) {
      console.log("error submitting quote: ", e);
      setError("Error submitting quote.");
    }
  };

  return (
    <>
      {active === 0 && (
        <Paper padding="md" shadow="xs" style={{ 
          marginTop: isMobile ? 70 : 100, 
          marginLeft: isMobile ? '10%' : '20%', 
          marginRight: isMobile ? '10%' : '20%', 
          padding: 30
        }}>
          <form onSubmit={handleSubmit}>
            <Text size="xl" weight={500} style={{ marginBottom: 15, textAlign: 'center' }}>Get a quote for licensing Redistricter's raw data</Text>
            <div>
              <div style={{ marginBottom: 15 }}>
                <TextInput
                  label="Name"
                  value={formState.name}
                  onChange={handleChange}
                  placeholder="Name"
                  name="name"
                  required
                />
              </div>
              <div style={{ marginBottom: 15 }}>
                <TextInput
                  label="Email"
                  type="email"
                  value={formState.email}
                  onChange={handleChange}
                  placeholder="Email"
                  name="email"
                  required
                />
              </div>
              <div>
                <TextInput
                  label="Organization"
                  value={formState.organizationName}
                  onChange={handleChange}
                  placeholder="Organization"
                  name="organizationName"
                  required
                />
              </div>
              <div style={{ marginTop: 15 }}>
                <TextInput
                  label="Data"
                  description="What datasets you're interested in licensing"
                  value={formState.data}
                  onChange={handleChange}
                  name="data"
                  // placeholder="Data"
                  required
                />
              </div>
              <div style={{ marginTop: 15 }}>
                <TextInput
                  label="Use case"
                  description="A brief explanation of how you plan to use the data"
                  value={formState.use}
                  onChange={handleChange}
                  name="use"
                  // placeholder="Data use"
                  required
                />
              </div>
            </div>
            <Group position="apart" style={{ marginTop: 15 }}>
              <Button type="submit" fullWidth={isMobile}>Submit</Button>
            </Group>
            {error && (
              <Text color="red" size="sm">
                {error} <br />
                <a href="mailto:colin@redistricter.com">Email me to report this bug.</a>
              </Text>
            )}
          </form>
        </Paper>
      )}
      {active === 1 && (
        <Paper padding="md" shadow="xs" style={{ 
          marginTop: isMobile ? 70 : 100, 
          marginLeft: isMobile ? '10%' : '20%', 
          marginRight: isMobile ? '10%' : '20%', 
          padding: 30 
        }}>
          <Text size="xl" weight={500} style={{ marginBottom: 20, textAlign: 'center' }}>Quote submitted</Text>
          <Text>Your quote has been submitted, I'll get back to you as soon as possible.</Text>
        </Paper>
      )}
    </>
  );
}
