import React, { useState, useEffect, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { formatDistanceToNow } from "date-fns";
import cx from 'clsx';
import { useNavigate } from "react-router-dom";
import {
    TextInput,
    Skeleton,
    Table,
    Checkbox,
    SegmentedControl,
    Center,
    Box,
    Select,
    MultiSelect,
    NumberInput,
    Button,
    FileInput,
    Progress,
    Text,
} from '@mantine/core';
import { Notifications, notifications } from '@mantine/notifications';
import {
    FiCopy,
    FiTrash2,
} from "react-icons/fi";
import { RiBankLine } from "react-icons/ri";
// @ts-ignore
import styles from "../../components/mainHeader.module.css";
import districtsList from "../data/statesDistricts.json";
import { AccountDropdown } from "../../components/accountDropdown";
import {
    deleteMap,
    duplicateMap,
    importMap
} from "../helpers/MapService";
import {
    fetchMapsList,
} from "./FetchMaps";
// @ts-ignore
import logo from "../assets/r3.png";
import { FiPlusCircle, FiUpload } from 'react-icons/fi';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { getFirebase } from '../../lib/firebase';
import { checkBrowserCompatibilityAndNotify } from '../helpers/browserUtils';
import Papa from 'papaparse';
import useUserRole from '../../hooks/useUserRole';
import CountySelector from './CountySelector';

type Map = {
    id: string;
    mapName: string;
    numDistricts: number;
    tags: Array<string>;
    state: string;
    date: { seconds: number, nanoseconds: number };
    shape: string;
    mapImage?: string;
    mapCountyFilter?: string | null;
};

const RIGHT_SIDEBAR_WIDTH = 240;

const useStyles = createUseStyles({
    rowSelected: {
        backgroundColor: '#EAF3FD',
    },
    rowUnSelected: {
        // backgroundColor: 'var(--mantine-color-blue-dark)',
    },
    main: {
        marginTop: 55,
        height: 'calc(100vh - 55px)',
    },
    rightSidebar: {
        width: RIGHT_SIDEBAR_WIDTH,
        height: 'calc(100% - 30px)',
        overflowY: 'scroll',
        padding: 20,
        paddingTop: 10,
    },
    maps: {
        width: `calc(100% - ${RIGHT_SIDEBAR_WIDTH + 40}px)`,
        float: 'left',
        height: '100%',
        overflowY: 'scroll',
    },
    noMapsMessage: {
        textAlign: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
        '& a': {
            color: 'rgb(99, 99, 255)',
            textDecoration: 'none',
        },
    },
    mapImage: {
        width: '50px',
        height: '40px',
        padding: 0,
        // objectFit: 'contain',
        '& svg': {
            maxWidth: '100%',
            maxHeight: '100%',
        },
    },
    actionButtons: {
        position: 'fixed',
        display: 'flex',
        gap: 10,
        bottom: 10,
        left: '50%',
        transform: 'translateX(-50%)',
        padding: 10,
        borderRadius: 5,
        backgroundColor: 'white',
        border: '2px solid #e0e0e0',
    }
});

function header(
    searchBox: React.ReactNode,
) {
    return (
        <div className={styles.headerContainer}>
            <div className={styles.headerContent}>
                <a href="/">
                    <img className={styles.logo} src={logo} alt="logo" />
                    <div>
                        <h1 className={styles.logoText}>
                            Redistricter
                            <span className={styles.tm}>™</span>
                        </h1>
                    </div>
                </a>
                <div style={{ width: '100%' }}>
                    {searchBox}
                </div>
                <div className={`${styles.buttons} ${styles.mobileHidden}`}>
                    <div className={styles.accountDropdown}>
                        <AccountDropdown mapHeaderCSS={false} />
                    </div>
                </div>
            </div>
        </div>
    )
}

function openMap(map: Map, navigate: any) {
    let newMapData;

    let countyFilter: any = map.mapCountyFilter;
    while (countyFilter && countyFilter.current && typeof countyFilter.current === 'object') {
        countyFilter = countyFilter.current;
    }

    if (countyFilter && countyFilter !== "null") {
        newMapData = {
            countyFilter: countyFilter,
            mapName: map.mapName
        };
    } else {
        newMapData = {
            mapName: map.mapName
        };
    }

    if (map.shape && map.shape !== "precincts") {
        navigate(`/map/${map.state}/${map.id}/${map.shape}`, { state: newMapData });
    } else {
        navigate(`/map/${map.state}/${map.id}/`, { state: newMapData });
    }
}

function Maprow(
    { map, selection, toggleRow }:
        { map: Map, selection: string[], toggleRow: (id: string) => void }
) {
    const styles = useStyles();
    const timeDifference = map.date ? formatDistanceToNow((map.date as any).toDate()) : "unknown";
    const navigate = useNavigate();

    // if (map.mapImage && map.mapImage !== "No districts to export") {
    //     console.log("Map Image: ", map.mapImage);
    // }

    if (!map.mapName) {
        map.mapName = "untitled map";
    }

    return (
        <tr
            key={map.id}
            onClick={() => openMap(map, navigate)}
            style={{ cursor: 'pointer' }}
            className={cx({ [styles.rowSelected]: selection.includes(map.id), [styles.rowUnSelected]: !selection.includes(map.id) })}
        >
            <td onClick={(e) => { e.stopPropagation(); }} style={{ cursor: 'default' }}>
                <Checkbox
                    checked={selection.includes(map.id)}
                    onChange={() => toggleRow(map.id)}
                />
            </td>
            <td style={{ width: 80, paddingTop: 0, paddingBottom: 0, paddingLeft: 5, paddingRight: 5 }}>
                {
                    (map.mapImage && map.mapImage !== "No districts to export" && !map.mapImage.includes("Error")) ?
                        <div className={styles.mapImage} dangerouslySetInnerHTML={{ __html: map.mapImage }} />
                        :
                        <></>
                }
            </td>
            <td style={{ width: 200 }}>{map.mapName.length > 25 ? map.mapName.substring(0, 25) + '...' : map.mapName}</td>
            {/* <td style={{ width: 100 }}>{map.state.match(/.{1,2}/g)?.join(',') || ''}</td> */}
            <td style={{ width: 100 }}>{map.state ? map.state.match(/.{1,2}/g)?.join(',') : ''}</td>
            <td>{timeDifference} ago</td>
            <td style={{ width: 100 }}>{map.shape === "blockgroups" ? "Block Groups" : "Precincts"}</td>
        </tr>
    );
}

export type County = {
    name: string;
    state: string;
    fips: string;
}

const StateSelector = (
    {
        currentState,
        onChange,
        isImport,
        selectedCounty,
        setSelectedCounty
    }:
        {
            currentState: any,
            onChange: (value: any) => void,
            isImport: boolean,
            selectedCounty: string[],
            setSelectedCounty: (value: string[]) => void
        }
) => {
    const [multiSelect, setMultiSelect] = useState(false);
    const [counties, setCounties] = useState<County[]>([]);
    const [showCountySelector, setShowCountySelector] = useState(false);

    const states = useMemo(() => [
        { value: "AL", name: "Alabama" },
        { value: "AK", name: "Alaska" },
        { value: "AZ", name: "Arizona" },
        { value: "AR", name: "Arkansas" },
        { value: "CA", name: "California" },
        { value: "CO", name: "Colorado" },
        { value: "CT", name: "Connecticut" },
        { value: "DE", name: "Delaware" },
        { value: "FL", name: "Florida" },
        { value: "GA", name: "Georgia" },
        { value: "HI", name: "Hawaii" },
        { value: "ID", name: "Idaho" },
        { value: "IA", name: "Iowa" },
        { value: "IL", name: "Illinois" },
        { value: "IN", name: "Indiana" },
        { value: "KS", name: "Kansas" },
        { value: "KY", name: "Kentucky" },
        { value: "LA", name: "Louisiana" },
        { value: "MA", name: "Massachusetts" },
        { value: "MD", name: "Maryland" },
        { value: "ME", name: "Maine" },
        { value: "MI", name: "Michigan" },
        { value: "MN", name: "Minnesota" },
        { value: "MS", name: "Mississippi" },
        { value: "MO", name: "Missouri" },
        { value: "MT", name: "Montana" },
        { value: "NE", name: "Nebraska" },
        { value: "NH", name: "New Hampshire" },
        { value: "NV", name: "Nevada" },
        { value: "NC", name: "North Carolina" },
        { value: "ND", name: "North Dakota" },
        { value: "NJ", name: "New Jersey" },
        { value: "NM", name: "New Mexico" },
        { value: "NY", name: "New York" },
        { value: "OH", name: "Ohio" },
        { value: "OK", name: "Oklahoma" },
        { value: "OR", name: "Oregon" },
        { value: "PA", name: "Pennsylvania" },
        { value: "RI", name: "Rhode Island" },
        { value: "SC", name: "South Carolina" },
        { value: "SD", name: "South Dakota" },
        { value: "TN", name: "Tennessee" },
        { value: "TX", name: "Texas" },
        { value: "UT", name: "Utah" },
        { value: "VA", name: "Virginia" },
        { value: "VT", name: "Vermont" },
        { value: "WA", name: "Washington" },
        { value: "WV", name: "West Virginia" },
        { value: "WI", name: "Wisconsin" },
        { value: "WY", name: "Wyoming" },
        { value: "DC", name: "Washington DC" },
        { value: "PR", name: "Puerto Rico" },
        { value: "UK", name: "England and Wales" },
    ], []); // empty dependency array because states doesn't depend on any props or state

    const loadCSV = (filePath: string) => {
        return new Promise((resolve, reject) => {
            Papa.parse(filePath, {
                download: true,
                header: true,
                complete: (results: any) => {
                    resolve(results.data);
                },
                error: (err: any) => {
                    reject(err);
                },
            });
        });
    };

    useEffect(() => {
        loadCSV('/fips.csv')
            .then((data: any) => {
                const filteredCounties = data.filter((row: any) => row.state === currentState);
                // console.log("Filtered Counties: ", filteredCounties, currentState, data);
                setCounties(filteredCounties);
            })
            .catch((err) => console.error(err));
    }, [currentState]);

    return (
        <div>
            {showCountySelector && (
                <CountySelector
                    counties={counties}
                    setShowCountySelector={setShowCountySelector}
                    selectedCounties={selectedCounty}
                    setSelectedCounties={setSelectedCounty}
                    state={currentState}
                />
            )}
            <div className={styles.stateSelector}>
                {(multiSelect && !isImport) ? (
                    <MultiSelect
                        data={states.filter((state) => state.value !== "UK").map((state) => ({ value: state.value, label: state.name }))}
                        value={currentState}
                        onChange={(value: any) => {
                            if (value.length === 0) {
                                onChange({ target: { value: null } });
                            } else if (value.length <= 10) {
                                onChange({ target: { value } });
                            } else {
                                alert('You can only select a maximum of 10 states.');
                            }
                        }}
                        searchable
                        label="States"
                        maxDropdownHeight={400}
                        style={{ marginTop: "10px" }}
                        placeholder="Select states"
                    />
                ) : (
                    <Select
                        data={states.map((state) => ({ value: state.value, label: state.name }))}
                        value={currentState}
                        onChange={(value) => onChange({ target: { value } })}
                        searchable
                        label="State"
                        maxDropdownHeight={400}
                        style={{ marginTop: "10px" }}
                        placeholder="Select state"
                    />
                )}
            </div>
            {(!isImport && currentState !== "UK") &&
                <>
                    <Checkbox
                        label="Multistate map"
                        style={{ marginTop: "15px" }}
                        // disabled={counties !== [0]}
                        onChange={(event) => {
                            setMultiSelect(event.target.checked);
                            if (event.target.checked) {
                                setSelectedCounty([]);
                            }
                        }}
                    />
                    <Text style={{ marginTop: 10 }} fw={500} size="sm">
                        Counties
                    </Text>
                    <div style={{ display: 'flex' }}>
                        {
                            selectedCounty.length > 0 &&
                                <p style={{
                                    fontWeight: 500,
                                    marginRight: 10,
                                    whiteSpace: 'nowrap'
                                }}>{selectedCounty.length} counties filtered</p>
                        }
                        <Button
                            onClick={() => setShowCountySelector(true)}
                            style={{ marginTop: 5 }}
                            fullWidth
                            variant="outline"
                            disabled={multiSelect}
                        >
                            {selectedCounty.length > 0 ? "Edit" : "Filter specific counties"}
                        </Button>
                    </div>

                </>
            }
        </div>
    );
};

export const MapBrowser = () => {
    const styles = useStyles();
    const [maps, setMaps] = useState<Map[]>([]);
    const [mapName, setMapName] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [selectedMaps, setSelectedMaps] = useState(['1']);
    const [searchTerm, setSearchTerm] = useState("");
    const [state, setState] = useState("AL");
    const [mapType, setMapType] = useState("congress");
    const [shape, setShape] = useState("blockgroups");
    const [districtCount, setDistrictCount] = useState(1);
    const [tags] = useState("");
    const [newType, setNewType] = useState("NewMap");
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [isImporting, setIsImporting] = React.useState(false);
    const [uploadError, setUploadError] = useState("");
    const [population, setPopulation] = useState(0);
    const [selectedCounties, setSelectedCounties] = useState<string[]>([]);
    const [splitPrecincts, setSplitPrecincts] = useState(true);
    const [progress, setProgress] = useState(0);
    const navigate = useNavigate();
    const { auth } = getFirebase();
    const [presets, setPresets] = useState({});
    const [selectedYear, setSelectedYear] = useState('2024Bg');
    const [selectOptions, setSelectOptions] = useState<Array<{ value: string; label: string }>>([]);
    const { role: stripeRole } = useUserRole(auth.currentUser?.uid, auth.currentUser?.email);
    const firestore = getFirestore();

    const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB
    const canLoadUk = stripeRole === "Organization" || stripeRole === "Admin";

    useEffect(() => {
        checkBrowserCompatibilityAndNotify();
    }, []);

    useEffect(() => {
        if (!auth.currentUser?.uid) {
            console.warn("User not logged in. Can't fetch task progress bar.");
        }
        const userId = auth.currentUser.uid;
        const taskRef = doc(firestore, `users/${userId}/tasks/${state}`);

        const unsubscribe = onSnapshot(taskRef, (doc: any) => {
            if (doc.exists()) {
                const data = doc.data();
                setProgress(data.progress || 0);
                if (data.progress === 100) {
                    // Handle completion
                    console.log("Import completed");
                    setIsImporting(false);
                }
            } else {
                console.log("No task progress document");
            }
        }, (error: any) => {
            console.error("Error getting document:", error);
        });

        // Cleanup subscription on component unmount
        return () => unsubscribe();
    }, [state, firestore, auth.currentUser?.uid]);

    useEffect(() => {
        loadPopulationData(state);
    }, [state]);

    const loadPopulationData = async (states: any) => {
        try {
            const stateList = Array.isArray(states) ? states : states.split(',');
            let totalPopulation = 0;

            for (const state of stateList) {
                const data = await import(`../data/${state}all.json`);
                totalPopulation += data.default[0].Pop20 || 0;
            }

            setPopulation(totalPopulation);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        // @ts-ignore
        const districts = districtsList.states[state]?.[mapType] || 1;
        setDistrictCount(districts);
    }, [state, mapType]);

    useEffect(() => {
        const loadPresets = async () => {
            const response = await fetch('/presets.csv');
            const reader = response?.body?.getReader();
            const result = await reader?.read(); // raw array
            const decoder = new TextDecoder('utf-8');
            const csv = decoder.decode(result?.value);
            Papa.parse(csv, {
                header: true,
                complete: (results) => {
                    const presetsData = results.data.reduce((acc: any, current: any) => {
                        acc[current?.State] = current;
                        return acc;
                    }, {});
                    setPresets(presetsData as any);
                }
            });
        };

        loadPresets();
    }, []);

    useEffect(() => {
        if (state in presets) {
            const options = ['2024Bg', '2022Bg', '2020Bg']
                .map(year => {
                    const label = year === '2024Bg' ? '2024 Congress' : year === '2022Bg' ? '2022 Congress' : '2020 Congress';
                    return { value: year, label: label, link: presets[state as keyof typeof presets][year] };
                })
                .filter(option => option.link !== 'N/A');

            setSelectOptions(options);

            // Automatically select the first available year if the current selection is not available
            if (!options.find(option => option.value === selectedYear)) {
                setSelectedYear(options[0]?.value || '2024Bg');
            }
        }
    }, [state, presets, selectedYear]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const toggleRow = (id: string) =>
        setSelectedMaps((current) =>
            current.includes(id) ? current.filter((map) => map !== id) : [...current, id]
        );

    const toggleAll = () =>
        setSelectedMaps((current) => (current.length === maps.length ? [] : maps.map((map: Map) => map.id))
        );

    const handleNewMap = async (inputShape: any, importData: any, countyFilter: string[]) => {
        if (Array.isArray(state) && !state.length) {
            return;
        }

        let newMapData = {
            numDistricts: districtCount,
            tags: tags,
            mapName: mapName,
            shape: shape,
            importData: importData,
            countyFilter: countyFilter,
        };

        if (importData) {
            newMapData.importData = importData;
            newMapData.numDistricts = importData.precinctsMap.length;
        }

        if (typeof inputShape === "string") {
            console.log("input shape changed to: ", inputShape);
            newMapData.shape = inputShape;
        }

        const urlState = (Array.isArray(state) && state.length) ? state.join('') : state;
        let path = `/map/${urlState}/`;
        if (newMapData.shape && newMapData.shape !== "precincts") {
            path += "blockgroups";
        }

        navigate(path, { state: newMapData });
    };

    const readFile = (file: any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            const extension = file.name.split(".").pop();

            reader.onload = (event: any) => {
                resolve(event.target.result);
            };

            reader.onerror = (error: any) => {
                reject(new Error("Error reading file: ", error));
            };

            if (extension === "shp") {
                reader.readAsArrayBuffer(file);
            } else {
                reader.readAsText(file);
            }
        });
    };

    const handleImportMap = async () => {
        if (state === "UK") {
            setUploadError("Importing maps for the UK is not supported yet");
            return;
        }

        if (isImporting) {
            console.log("Already importing a map");
            return;
        }

        if (!uploadedFile) {
            setUploadError("No file selected");
            console.log("No file selected");
            return;
        }

        if (uploadedFile.size > MAX_FILE_SIZE) {
            setUploadError("File is too large. Maximum allowed size is 100MB");
            notifications.show({
                title: "File too large",
                message: `Maximum allowed file size is 100MB.`,
                color: 'red',
                withBorder: true,
            });
            console.warn("File is too large. Maximum allowed size is 100MB");
            return;
        }

        const extension = uploadedFile.name.split(".").pop();
        const validExtensions = ['shp', 'geojson', 'json'];

        if (!validExtensions.includes(extension || "")) {
            setUploadError("Invalid file type");
            notifications.show({
                title: 'Invalid file type',
                message: '',
                color: 'red',
                withBorder: true,
            });
            return;
        }

        console.log("file type: ", extension);

        setUploadError("");
        setIsImporting(true);

        let importedMap;

        try {
            const shapefileString = await readFile(uploadedFile);
            const result = await importMap(
                shapefileString,
                state,
                extension as any,
                shape,
                splitPrecincts,
            );

            // @ts-ignore
            if (result.error) {
                // @ts-ignore
                throw new Error(result.error);
            }

            handleNewMap(shape, result, []);
        } catch (error: any) {
            notifications.show({
                title: 'Failed to upload the map',
                message: error.message,
                color: 'red',
                withBorder: true,
            });
            console.log("Failed to upload the map", error.message);
            setUploadError(error.message || "Failed to upload the map");
        }

        setIsImporting(false);

        if (!importedMap) {
            console.warn("Failed to upload the map");
            setUploadError("Failed to upload the map");
            return;
        }

        handleNewMap(shape, importedMap, []);
    };

    useEffect(() => {
        fetchMapsList().then((maps: Map[]) => {
            setMaps(maps); // No need to parse StorageMapInfo from JSON.
            setIsLoading(false);
        });
    }, []);

    const filteredMaps = useMemo(() => {
        if (!searchTerm) return maps;

        // Check if the search term starts with "state:" and extract the state code
        const stateSearchMatch = searchTerm.match(/^state:(\w{2})$/i);
        if (stateSearchMatch) {
            const stateCode = stateSearchMatch[1].toUpperCase();
            return maps.filter(map => {
                // Use a regular expression to find the state code in the concatenated state string
                const regex = new RegExp(stateCode);
                return regex.test(map.state);
            });
        }

        // Default search by map name
        return maps.filter(map =>
            (map.mapName ? map.mapName.toLowerCase() : "").includes(searchTerm.toLowerCase())
        );
    }, [searchTerm, maps]);

    const handleDelete = async () => {
        await Promise.all(
            selectedMaps.filter(mapId => mapId !== '1').map((mapId: string) => deleteMap(mapId))
        );
        const maps = await fetchMapsList();
        setMaps(maps);
        setSelectedMaps(['1']);
        notifications.show({
            title: 'Map(s) deleted',
            message: '',
            withBorder: true,
        });
    };

    const handleDuplicate = async () => {
        await Promise.all(
            [...selectedMaps].filter(mapId => mapId !== '1').map((mapId: string) => duplicateMap(mapId))
        );
        const maps = await fetchMapsList();
        setMaps(maps);
        setSelectedMaps(['1']);
        notifications.show({
            title: 'Map(s) duplicated',
            message: '',
            withBorder: true,
        });
    };

    return (
        <>
            {header(
                <TextInput
                    placeholder={
                        isLoading ? 'Search maps' :
                            `Search ${maps.length} maps or filter by state (e.g. state:CA)`
                    }
                    mb="md"
                    style={{
                        width: '80%', marginTop: 'auto',
                        marginBottom: 'auto', marginRight: '20%', marginLeft: '20%'
                    }}
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            )}
            <Notifications position="bottom-right" zIndex={1000} style={{ width: 300 }} />
            <div className={styles.main}>
                <div className={styles.maps}>
                    {isLoading ? (
                        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                            {Array(30).fill(0).map((_, index) => (
                                <Skeleton key={index} height={40} mt={10} radius="lg" />
                            ))}
                        </div>
                    ) : maps.length === 0 ? (
                        <div className={styles.noMapsMessage}>
                            <h2>No maps</h2>
                            <p>
                                When you create a map it will appear here in the map browser
                            </p>
                            <p>
                                Read more about this in the <a href="https://docs.redistricter.com/guide/quickstart">user guide</a>
                            </p>
                        </div>
                    ) : (
                        <Table miw={800} verticalSpacing="sm">
                            <thead>
                                <tr>
                                    <th style={{ width: 40 }}>
                                        <Checkbox
                                            onChange={toggleAll}
                                            checked={selectedMaps.length === maps.length}
                                            indeterminate={selectedMaps.length > 0 && selectedMaps.length !== maps.length}
                                        />
                                    </th>
                                    <th>Preview</th>
                                    <th>Name</th>
                                    <th>State</th>
                                    <th>Edited</th>
                                    <th>Shape</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filteredMaps
                                        .filter((map: Map) => map && map.date)
                                        .sort((a: any, b: any) => b.date.toDate() - a.date.toDate())
                                        .map((map: Map, index: number) => (
                                            <Maprow
                                                key={index}
                                                map={map}
                                                selection={selectedMaps}
                                                toggleRow={toggleRow}
                                            />
                                        ))
                                }
                            </tbody>
                        </Table>
                    )}
                    {
                        (selectedMaps.length > 1 || (selectedMaps.length === 1 && selectedMaps[0] !== '1')) &&
                        <div className={styles.actionButtons}>
                            <Button
                                onClick={handleDelete}
                                variant="filled"
                                color="blue"
                                size="sm"
                            >
                                <FiTrash2 />&nbsp;&nbsp;Delete Map
                            </Button>
                            <Button
                                onClick={handleDuplicate}
                                variant="filled"
                                color="blue"
                                size="sm"
                            >
                                <FiCopy />&nbsp;&nbsp;Duplicate Map
                            </Button>
                        </div>
                    }
                </div>
                <div className={styles.rightSidebar}>
                    <SegmentedControl
                        fullWidth
                        orientation="vertical"
                        color="blue"
                        size="md"
                        data={[
                            {
                                value: 'NewMap',
                                label: (
                                    <Center>
                                        <FiPlusCircle style={{ width: 16, height: 16 }} />
                                        <Box ml={10}>New Map</Box>
                                    </Center>
                                ),
                            },
                            {
                                value: 'ImportMap',
                                label: (
                                    <Center>
                                        <FiUpload style={{ width: 16, height: 16 }} />
                                        <Box ml={10}>Import Map</Box>
                                    </Center>
                                ),
                            },
                            {
                                value: 'PremadeMap',
                                label: (
                                    <Center>
                                        <RiBankLine style={{ width: 16, height: 16 }} />
                                        <Box ml={10}>Use Official Map</Box>
                                    </Center>
                                ),
                            },
                        ]}
                        onChange={value => setNewType(value)}
                    />
                    {newType === "NewMap" ? (
                        <>
                            <TextInput
                                value={mapName}
                                onChange={(event) => setMapName(event.currentTarget.value)}
                                label="Map name"
                                placeholder="Untitled map"
                                style={{ marginTop: "10px" }}
                                autoComplete="off"
                            />
                            <StateSelector
                                currentState={state}
                                onChange={(e) => setState(e.target.value)}
                                isImport={false}
                                selectedCounty={selectedCounties}
                                setSelectedCounty={setSelectedCounties}
                            />
                            <Select
                                data={
                                    state === "UK" ? [
                                        { value: "congress", label: "Parliament Constituencies" },
                                    ] : [
                                        { value: "congress", label: "Congress" },
                                        { value: "senate", label: "State House" },
                                        { value: "house", label: "State Senate" }
                                    ]}
                                value={mapType}
                                onChange={(value: string) => setMapType(value)}
                                label="Map type"
                                style={{ marginTop: "10px" }}
                            />
                            <NumberInput
                                placeholder="1"
                                value={districtCount}
                                onChange={(value: number) => setDistrictCount(value > 0 ? value : 0)}
                                label="District count"
                                style={{ marginTop: "10px" }}
                                min={0}
                            />
                            <p style={{
                                padding: '0px',
                                marginTop: '5px',
                                marginBottom: '0',
                                color: 'gray',
                                fontSize: '13px'
                            }}>
                                {(selectedCounties.length === 0 && state !== "UK") &&
                                    `${Math.round(population / districtCount).toLocaleString()} people per district`
                                }
                            </p>
                            <Select
                                data={[
                                    { value: "blockgroups", label: state === "UK" ? "Mid-level Output Areas" : "Block Groups" },
                                    { value: "precincts", label: state === "UK" ? "Output Areas" : "Precincts" }
                                ]}
                                value={shape}
                                onChange={(value: string) => setShape(value)}
                                label="Shape"
                                style={{ marginTop: "10px" }}
                            />
                            <Button
                                onClick={() => handleNewMap(shape, null, selectedCounties)}
                                style={{ marginTop: "20px" }}
                                disabled={state === "UK" && !canLoadUk}
                                fullWidth
                            >
                                Create Map
                            </Button>
                            {state === "UK" && !canLoadUk && (
                                <>
                                    <p style={{ color: "red", fontSize: "14px", marginTop: "10px", textAlign: "center" }}>
                                        You must have a commercial subscription to load UK data.
                                        <br /><br />
                                        <a href="/signup/commercial" style={{ color: "rgb(99, 99, 255)", textDecoration: "none" }}>
                                            Upgrade your plan here
                                        </a>
                                    </p>
                                </>
                            )}
                        </>
                    ) : newType === "ImportMap" ? (
                        <>
                            <StateSelector
                                currentState={state}
                                onChange={(e) => setState(e.target.value)}
                                isImport={true}
                                selectedCounty={selectedCounties}
                                setSelectedCounty={setSelectedCounties}
                            />

                            <Select
                                data={[
                                    { value: "blockgroups", label: "Block Groups" },
                                    { value: "precincts", label: "Precincts" }
                                ]}
                                value={shape}
                                onChange={(value: any) => setShape(value)}
                                label="Shape"
                                style={{ marginTop: "10px" }}
                            />

                            <FileInput
                                // placeholder="Input shapefile"
                                onChange={(event: any) => {
                                    if (event) {
                                        setUploadedFile(event);
                                    }
                                }}
                                label="Shapefile"
                                accept=".shp, .geojson, .json, application/geo+json, application/json"
                                description="Currently only .shp, .geojson, and .json files are supported"
                                style={{ marginTop: "10px" }}
                                icon={<FiUpload size={14} />}
                            />

                            <Checkbox
                                checked={splitPrecincts}
                                onChange={(event) => setSplitPrecincts(event.target.checked)}
                                label="Split precincts"
                                description="Data will be more accurate but the import may take longer to process"
                                style={{ marginTop: "15px" }}
                            />
                            <Button
                                onClick={handleImportMap}
                                loading={isImporting}
                                style={{ marginTop: "20px" }}
                                fullWidth
                            >
                                Upload map
                            </Button>

                            {(isImporting && splitPrecincts && !uploadError) && (
                                <Progress
                                    value={progress}
                                    animate
                                    style={{ marginTop: "20px" }}
                                />
                            )}

                            <p
                                style={{
                                    color: "red",
                                    fontSize: "14px",
                                    margin: "25px",
                                    marginTop: "10px"
                                }}
                            >
                                {uploadError && <>Error: {uploadError}</>}
                            </p>
                        </>
                    ) : (
                        <>
                            <StateSelector
                                currentState={state}
                                onChange={(e) => setState(e.target.value)}
                                isImport={true}
                                selectedCounty={selectedCounties}
                                setSelectedCounty={setSelectedCounties}
                            />
                            <Select
                                data={selectOptions}
                                value={selectedYear}
                                onChange={(value: string) => setSelectedYear(value)}
                                label="Year"
                                style={{ marginTop: "10px" }}
                            />
                            <Button
                                onClick={() => window.location.href =
                                    new URL(presets[state as keyof typeof presets][selectedYear] as string, window.location.origin).pathname}
                                disabled={presets[state as keyof typeof presets][selectedYear] === "N/A"}
                                style={{ marginTop: "20px" }}
                                fullWidth
                            >
                                Open map
                            </Button>

                            <p
                                style={{
                                    color: "gray",
                                    fontSize: "14px",
                                    margin: "25px",
                                    marginTop: "10px"
                                }}
                            >
                                Any changes made to the map will be applied to a duplicate map
                            </p>
                        </>
                    )}

                    {(shape === "precincts" && state === "NY") && (
                        <p
                            style={{
                                fontSize: "14px",
                                marginTop: "10px"
                            }}
                        >
                            *Due to discrepancies in the precinct shapefile, block groups are recommended for {state}.
                        </p>
                    )}
                </div>
            </div>
        </>
    );
}

export default MapBrowser;