import React, { useEffect, useState } from 'react';
import { useForm } from '@mantine/form';
import {
    TextInput,
    Button,
    Select,
    Textarea,
    Text,
    Checkbox,
} from '@mantine/core';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import useUserRole from '../hooks/useUserRole';
import { getFirebase } from "../lib/firebase";

interface FormValues {
    uid: string | null;
    email: string | null;
    stripeRole: string | null;
    source: string;
    useForWork: string;
    relatedWork: string;
    recentUse: string;
    experience: string;
    importanceOfDatasets: string;
    integration: string;
    requestedFeatures: string;
    bugs: string;
}

const FORM_SPACING = 35;

export const Form: React.FC = () => {
    const { auth } = getFirebase();
    const { role: stripeRole } = useUserRole(auth.currentUser?.uid, auth.currentUser?.email);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [useForWork, setUseForWork] = useState('');

    const form = useForm<FormValues>({
        initialValues: {
            uid: auth.currentUser?.uid,
            email: auth.currentUser?.email,
            stripeRole: stripeRole,
            source: '',
            useForWork: '',
            relatedWork: '',
            recentUse: '',
            experience: '',
            importanceOfDatasets: '',
            integration: '',
            requestedFeatures: '',
            bugs: '',
        },
        validate: {
            // source: (value: string) => value.trim() !== '' || 'This field is required',
            // useForWork: (value: string) => value.trim() !== '' || 'This field is required',
        },
    });

    useEffect(() => {
        const userStuff = auth.onAuthStateChanged((user: any) => {
            if (user && form.values.uid !== user.uid) {
                setIsLoading(false);
                form.setValues({
                    ...form.values,
                    uid: user.uid,
                    email: user.email,
                });
            }
        });

        // Cleanup function to remove the listener when the component unmounts
        return () => userStuff();
    }, [auth, form]);

    useEffect(() => {
        if (form.values.stripeRole !== stripeRole) {
            form.setValues({ 'stripeRole': stripeRole });
        }
    }, [form, stripeRole]);

    const db = getFirestore();

    useEffect(() => {
        const checkSubmission = async () => {
            if (!isLoading) {
                const docId = auth.currentUser?.uid;
                const docRef = doc(db, 'survey1', docId);
                const docSnap = await getDoc(docRef);
        
                if (docSnap.exists()) {
                    setIsSubmitted(true);
                }
            } 
        };

        checkSubmission();
    }, [db, auth, isLoading]);

    const handleSubmit = async (values: FormValues) => {
        console.log(values);
        try {
            const docId = auth.currentUser?.uid;
            await setDoc(doc(db, 'survey1', docId), values);
            console.log('Form submitted successfully');
            setIsSubmitted(true);
        } catch (error) {
            console.error('Error submitting form: ', error);
            form.setErrors({ form: 'Failed to submit form.' });
        }
    };

    const handleEditResponse = async () => {
        const docId = auth.currentUser?.uid;
        const docRef = doc(db, 'survey1', docId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            form.setValues(docSnap.data() as FormValues);
        }

        setIsSubmitted(false);
    };

    const handleCheckboxChange = (value: string) => {
        setUseForWork(value);
        form.setValues({ 'useForWork': value });
    };

    return (
        <div style={{ maxWidth: 500, margin: 'auto', marginBottom: 100, marginTop: 80 }}>
            {isSubmitted ? (
                <div style={{ marginBottom: '80vh'}}>
                    <Text size="lg">Thank you for your submission!</Text>
                    <Button fullWidth onClick={handleEditResponse}>Edit your response</Button>
                    {/* <Button fullWidth onClick={() => setIsSubmitted(false)}>Submit another response</Button> */}
                </div>
            ) : (
                <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                    <TextInput
                        label="How did you hear about Redistricter?"
                        placeholder="Enter response"
                        {...form.getInputProps('source')}
                        style={{ marginBottom: FORM_SPACING }}
                        required
                    />
                    <Text size="sm" color="red">{form.errors.useForWork}</Text>
                    <Text size="sm" fw={500} style={{ marginBottom: 5, color: "#212529"}} aria-required>Do you use Redistricter for work? *</Text>
                    <Checkbox
                            checked={useForWork === 'yes'}
                            label="Yes"
                            onChange={() => handleCheckboxChange('yes')}
                        />
                    <Checkbox
                        checked={useForWork === 'no'}
                        label="No"
                        onChange={() => handleCheckboxChange('no')}
                        style={{ marginTop: 10, marginBottom: FORM_SPACING}}
                    />
                    <Textarea
                        label="Please describe your related work and why Redistricter is useful for it."
                        placeholder="Enter response"
                        {...form.getInputProps('relatedWork')}
                        style={{ marginBottom: FORM_SPACING }}
                        disabled={useForWork !== 'yes'}
                    />
                    <Textarea
                        label="Can you provide a specific example of when you recently used Redistricter? How was that process and what was the outcome?"
                        placeholder="Enter response"
                        {...form.getInputProps('recentUse')}
                        style={{ marginBottom: FORM_SPACING }}
                    />
                    <Textarea
                        label="How well does Redistricter integrate with your existing workflows? Are you performing any additional steps to integrate Redistricter data and visualizations with your work?"
                        placeholder="Enter response"
                        {...form.getInputProps('integration')}
                        style={{ marginBottom: FORM_SPACING }}
                    />
                    <Textarea
                        label="How would you describe the overall experience of using Redistricter? Is there anything particularly intuitive or challenging about it?"
                        placeholder="Enter response"
                        {...form.getInputProps('experience')}
                        style={{ marginBottom: FORM_SPACING }}
                    />
                    <Select
                        label="How important is it for you to have access to election datasets from recent elections?"
                        placeholder="Select"
                        {...form.getInputProps('importanceOfDatasets')}
                        data={[
                            { value: 'not_at_all', label: 'Not at all important' },
                            { value: 'slightly', label: 'Slightly important' },
                            { value: 'moderately', label: 'Moderately important' },
                            { value: 'very', label: 'Very important' },
                            { value: 'extremely', label: 'Extremely important' },
                        ]}
                        style={{ marginBottom: FORM_SPACING }}
                        required
                    />
                    <Textarea
                        label="Are there any features or services you would like to see added to Redistricter?"
                        placeholder="Enter response"
                        {...form.getInputProps('requestedFeatures')}
                        style={{ marginBottom: FORM_SPACING }}
                    />
                    <Textarea
                        label="What bugs have you encountered using Redistricter?"
                        placeholder="Enter response"
                        {...form.getInputProps('bugs')}
                        style={{ marginBottom: FORM_SPACING }}
                    />
                    <Button type="submit" fullWidth style={{ marginBottom: 10 }}>Submit</Button>
                    {form.errors.form && <Text color="red">{form.errors.form}</Text>}
                </form>
            )}
        </div>
    );
};