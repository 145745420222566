import React, { useState } from "react";

const prices = [
    {
        title: "Individual",
        code: "individual",
        monthlyPrice: "12",
        yearlyPrice: "120",
        features: ["Unlimited Use"],
        pricePer: "/",
        includeChecks: true,
        message: "Get Started"
    },
    {
        title: "Commercial",
        code: "commercial",
        monthlyPrice: "24",
        yearlyPrice: "240",
        features: [
            "Unlimited Use",
            "More demographic datasets",
            "Commercial use license",
            "Export district-level data",
            "Redistricter UK"
        ],
        pricePer: "/User/",
        includeChecks: true,
        message: "Get Started"
    },
    {
        title: "Data License",
        singlePrice: "Custom",
        features: [
            // "Access Redistricter's raw data library.",
            "For anyone interested in accessing Redistricter's raw data for use outside of the application."
        ],
        includeChecks: false,
        message: "Get a Quote"
    }
];

export default function Pricing() {
    const [isYearly, setIsYearly] = useState(false);

    function handleSignup(planType: string) {
        let url = "/signup/" + planType;
        if (isYearly) {
            url += "/yearly";
        }
        window.location.href = url;
    }

    return (
        <div
            className="landingPageSection mt-16 md:mt-28 px-5 bg-transparent rounded-none w-full overflow-visible"
            id="pricing"
        >
            <p className="text-4xl md:text-5xl font-bold pb-5 text-center">
                Pricing
            </p>
            <p className="landingPageSubtitle text-center mb-5">
                Choose the plan that works for you
            </p>
            {/* Yearly/monthly price toggle */}
            <div className="flex justify-center items-center gap-4 max-w-sm mx-auto text-center pb-4">
                <button
                    className={`mt-4 mb-2 w-full ${
                        !isYearly
                            ? "bg-blue-600 text-white hover:bg-blue-700"
                            : "bg-blue-100 hover:bg-blue-200 text-blue-900"
                    } font-bold py-3 px-4 rounded transition duration-300`}
                    onClick={() => setIsYearly(false)}
                >
                    Monthly
                </button>
                <button
                    className={`mt-4 mb-2 w-full ${
                        isYearly
                            ? "bg-blue-600 text-white hover:bg-blue-700"
                            : "bg-blue-100 hover:bg-blue-200 text-blue-900"
                    } font-bold py-3 px-4 rounded flex items-center justify-center gap-1 transition duration-300`}
                    onClick={() => setIsYearly(true)}
                >
                    Yearly<p className="">(-20%)</p>
                </button>
            </div>
            <div className="flex flex-col md:flex-row justify-center items-stretch gap-4 w-full">
                {prices.map((price, index) => (
                    <div
                        key={index}
                        className="flex flex-col gap-4 p-5 rounded-[15px] w-full md:w-1/3 bg-neutral-50 outline-[1px] outline-neutral-200 outline
                         shadow-xl flex-grow"
                    >
                        <p className="text-2xl font-semibold">{price.title}</p>
                        <p className="text-4xl font-bold">
                            {price.singlePrice
                                ? price.singlePrice
                                : `$${
                                      isYearly
                                          ? price.yearlyPrice
                                          : price.monthlyPrice
                                  }`}
                            {price.singlePrice ? null : (
                                <span className="text-[1rem]">
                                    {isYearly
                                        ? `${price.pricePer}Year`
                                        : `${price.pricePer}Month`}
                                </span>
                            )}
                        </p>
                        <ul className="text-left w-full flex-grow">
                            {price.features.map((feature, fIndex) => (
                                <li
                                    key={fIndex}
                                    className="flex items-center leading-[1.5rem]"
                                >
                                    {price.includeChecks && (
                                        <svg
                                            className="w-6 h-6 text-green-600 mr-2 mb-1"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M5 13l4 4L19 7"
                                            />
                                        </svg>
                                    )}
                                    <p className="-mt-1">
                                        {feature === "Redistricter UK" ? (
                                            <a href="/uk" className="styleLink">
                                                {feature}
                                            </a>
                                        ) : (
                                            feature
                                        )}
                                    </p>
                                </li>
                            ))}
                        </ul>
                        <button
                            className="styleBtn mt-4 mb-2 w-full rounded"
                            onClick={() => {
                                if (price.code) {
                                    handleSignup(price.code);
                                } else {
                                    window.location.href = "/quote";
                                }
                            }}
                        >
                            {price.message}
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
}
