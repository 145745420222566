import React, { Component, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

type State = {
  hasError: boolean;
  error?: Error;
  errorInfo?: React.ErrorInfo;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error }; 
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: 16 }}>
          <h1 style={{ color: '#991b1b', fontSize: 'medium', fontWeight: 'bold' }}>Something went wrong :(</h1>
          <br/>
          <h1 style={{ fontSize: 'medium' }}>{this.state.error?.toString()}</h1>
          <br/>
            <a href="mailto:colin@redistricter.com?subject=Bug%20Report" className='text-blue-800'>Report this bug</a>
          <br/>
          {/* <p>You can report this bug by <a href="mailto:colin@redistricter.com" className='text-blue-800'>emailing me</a>.</p>
          <br/>
          <details className='text-nowrap'>
            {this.state.error?.toString()}
            <br />
            {this.state.errorInfo?.componentStack}
          </details>
          <br/> */}
        </div>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;