import React from "react";

export default function Logos() {
    return (
        <div className="landingPageSection bg-neutral-950 w-full flex flex-col items-center gap-4 lg:gap-10 py-5 lg:py-10">
            <p className="text-white text-center font-semibold text-xl">Used for analysis by</p>
            <div className="w-full flex flex-wrap justify-evenly gap-4 px-4 mb-4">
                <div className="w-1/4 max-w-xs h-28">
                    <img src="/landing-page/sabato.png" alt="Sabato Crystal Ball" className="h-full mx-auto object-contain filter invert grayscale" />
                </div>
                <div className="w-1/4 max-w-xs h-28">
                    <img src="/landing-page/nyt.png" alt="New York Times" className="h-full mx-auto object-contain filter invert grayscale" />
                </div>
                <div className="w-1/4 max-w-xs h-28">
                    <img src="/landing-page/echelon.png" alt="Echelon Insights" className="h-full mx-auto object-contain grayscale" />
                </div>
            </div>
        </div>
    );
}
