import React from "react";
import "../css/footer.css";
import logo from "../assets/r3.png";

export function MainFooter() {
  return (
    <footer>
      <div className="footerlogo">
        <img src={logo} alt="Logo" width="40" height="40"></img>
        <span style={{ marginLeft: "15px" }}>Redistricter</span>
        <span
          style={{ marginLeft: "0px", fontWeight: "300", fontSize: "24px" }}
        >
          ™
        </span>
      </div>
      <div className="footer-sections-group">
        <div className="footer-section">
          <h3>Contact</h3>
          <p>
            <a href="mailto:colin@redistricter.com">Email</a>
          </p>
          <p>
            <a href="https://twitter.com/Redistricter">Twitter</a>
          </p>
          {/* <p>
            <a href="https://www.instagram.com/redistricter/">Instagram</a>
          </p> */}
          {/* <p>
            <a href="https://discord.gg/4BDtkVGz79">Discord</a>
          </p> */}
        </div>
        <div className="footer-section">
          <h3>Pages</h3>
          <p>
            <a href="/map/OK/demo/blockgroups'">Demo</a>
          </p>
          <p>
            <a href="/changelog">Changelog</a>
          </p>
          <p>
            <a href="/account">Account Settings</a>
          </p>
          <p>
            <a href="/datadictionary">Data Dictionary</a>
          </p>
          <p>
            <a href="/uk">UK</a>
          </p>
          {/* <p>
            <a href="https://old.redistricter.com">Old site</a>
          </p> */}
        </div>
        <div className="footer-section">
          <h3>About</h3>
          <p>
            <a href="/about">About</a>
          </p>
          <p>
            <a href="https://docs.redistricter.com">Docs</a>
          </p>
          <p>
            <a href="/tos">Terms of Service</a>
          </p>
          {/* <p>
            <a href="https://geoshaper.com">Geoshaper</a>
          </p> */}
          <p>
            <a href="https://colindm.com">Personal Site</a>
          </p>
        </div>
      </div>
      <div className="footer-bottom" style={{ marginBottom: -15 }}>
        <p>5900 Balcones Drive, STE 4000, Austin, TX 78731</p>
      </div>
      <div className="footer-bottom">
        <p>By Colin Miller&nbsp;&nbsp;&nbsp;© 2024 Redistricter, LLC.</p>
      </div>
    </footer>
    // <footer>
    // 	<div className="footerlogo">
    // 		<img src={logo} alt="Logo" width="50" height="50"></img>
    // 		<span style={{ marginLeft: '25px' }}>Redistricter</span>
    // 		<span style={{ marginLeft: '0px', fontWeight: '300', fontSize: '24px' }}>™</span>
    // 	</div>
    // 	<div className="firstline">
    // 		<p><a href="mailto:colin@redistricter.com">Email</a></p>
    // 		<p><a href="https://twitter.com/Redistricter">Twitter</a></p>
    // 		<p><a href="/map/OK/demo">Demo</a></p>
    // 		{/* <p><a href="https://www.buymeacoffee.com/redistricter">Donate</a></p> */}
    // 		<p><a href="https://old.redistricter.com">Old site</a></p>
    // 		<p><a href="/tos">Terms of service</a></p>
    // 		<p><a href="/credits">Credit</a></p>
    // 	</div>
    // </footer>
  );
}
