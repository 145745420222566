export const PRICES = {
    'individual': {
        'monthly': 12,
        'yearly': 120,
    },
    'commercial': {
        'monthly': 24,
        'yearly': 240,
    },
}
