import { notifications } from "@mantine/notifications";

export const checkBrowserCompatibilityAndNotify = () => {
  const userAgent = window.navigator.userAgent;
  const msie = userAgent.indexOf('MSIE '); // Old IE versions
  const trident = userAgent.indexOf('Trident/'); // IE 11

  const safariAgent = userAgent.indexOf('Safari') !== -1;
  const chromeAgent = userAgent.indexOf('Chrome') !== -1;
  const isIE = msie > 0 || trident > 0;

  // Check for Internet Explorer
  if (isIE) {
    notifications.show({
      title: "Browser Compatibility Warning",
      message: `You are using Internet Explorer, which is not supported by Redistricter and may not work.
      Please consider using a modern browser like Chrome or Firefox.`,
      color: "red",
      autoClose: false,
    });
  }

  // Make sure this is Safari and not Chrome
  if (safariAgent && !chromeAgent) {
    const safariVersionMatch = userAgent.match(/Version\/(\d+).(\d+)/);
    if (safariVersionMatch) {
      const majorVersion = parseInt(safariVersionMatch[1], 10);
      if (majorVersion <= 16) {
        notifications.show({
          title: "Browser Compatibility Warning",
          message: `You are using Safari version 16 or lower, which might break Redistricter. 
          Please consider updating your browser or using a different browser like Chrome or Firefox.`,
          color: "red",
          autoClose: false,
        });
      }
    }
  }
};