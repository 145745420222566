import React, {
  ReactNode,
  FC,
  createContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { getRole } from "./roles";
import { getFirebase } from "../../lib/firebase";
import useUserRole from '../../hooks/useUserRole';

export interface Account {
  role: string;
  daysLeft: number | null;
  user: any;
  email: string;
}

export interface AppSettings {
  account: Account;
  setUser: (user: any) => void;
  getStripeRole: () => Promise<string>;
}

const AccountInitialState: Account = {
  role: "",
  daysLeft: null,
  user: null,
  email: ""
};

export const AppSettingsContext = createContext<AppSettings>({
  account: { ...AccountInitialState },
  setUser: () => null,
  getStripeRole: () => Promise.resolve("")
});

interface Props {
  children: ReactNode;
}

export const AppSettingsProvider: FC<Props> = ({ children }) => {
  const [account, setAccount] = useState<Account>({ ...AccountInitialState });
  const [stripeRoleState, setStripeRoleState] = useState<string | null>(null);
  const [loadingStripeRole, setLoadingStripeRole] = useState<boolean>(true);
  const { auth } = getFirebase();
  // const { role: stripeRole, subscriptions, loading: roleLoading, error } = useUserRole(account.user?.uid, account.user?.email);
  // console.log(stripeRole, subscriptions, roleLoading, error);
  const { role: stripeRole } = useUserRole(account.user?.uid, account.user?.email);

  useEffect(() => {
    if (stripeRole) {
      setStripeRoleState(stripeRole);
      setLoadingStripeRole(false);
    }
  }, [stripeRole]);

  const handleSetUser = useCallback(
    async (user: any) => {
      const { email, role, daysLeft } = await getRole(user, auth);

      setAccount({
        role,
        daysLeft: daysLeft || null,
        user,
        email
      });
    },
    [auth]
  );

  const handleGetStripeRole = useCallback(async () => {
    while (loadingStripeRole) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }

    return stripeRoleState || "";
  }, [stripeRoleState, loadingStripeRole]);

  return (
    <AppSettingsContext.Provider
      value={{
        account,
        setUser: handleSetUser,
        getStripeRole: handleGetStripeRole
      }}
    >
      {children}
    </AppSettingsContext.Provider>
  );
};
