import React from "react";
import styles from "./guide.module.css";
import { Image } from "@mantine/core";
import { Helmet } from 'react-helmet';

export function UkInfo() {
	return (
		<>
			<div className={styles.main}>
				<Helmet>
					<title>Guide</title>
					<meta name="description" content="Information on Redistricter UK" />
				</Helmet>
				<h1>Redistricter UK Edition</h1>
				{/* <p>
					Redistricter UK is experimental and currently a work-in-progress.
				</p> */}

                <div className={styles.section}>
					<div className={styles.content}>
						<h2>Access</h2>
						<p>
							Redistricter UK is only accessible to commercial Redistricter subscribers.
							If you're not already a commercial subscriber, you can <a className={styles.link} href="/signup/commercial">sign up here</a>.
						</p>
					</div>
                </div>

				<div className={styles.section}>
					<div className={styles.content}>
						<h2>Datasets</h2>
                        <li>
                            Population and race
                        </li>
						<li>
                            Religion
                        </li>
                        <li>
                            Passports held
                        </li>
						<li>
                            Immigration status and year of arrival
                        </li>
                        <li>
                            Sex/gender
                        </li>
                        <li>
                            Education
                        </li>
                        <li>
                            English Proficiency
                        </li>
						<p>
							Currently Redistricter UK only includes data for England and Wales.
						</p>
					</div>
					<div className={styles.imageContainer}>
						<Image
							maw="100%"
							radius="md"
							src={require(`../screenshots.nosync/UK.png`)}
							alt="Redistricter UK"
							// caption="The map browser"
						/>
					</div>
				</div>

				<h1>Contact</h1>
				<p style={{ marginBottom: 100 }}>
					If you have any further questions about Redistricter, feel free to
					email me at{" "}
					<a className={styles.link} href="mailto:colin@redistricter.com">
						colin@redistricter.com
					</a>
					.
				</p>
			</div>
		</>
	);
}
