import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { createUseStyles } from "react-jss";
import {
    Select,
    Table,
} from '@mantine/core';
import { useMediaQuery } from "@mantine/hooks";
import statesData from "../assets/states.json";
import allElections from '../mapui/data/allElections.json';
import { Helmet } from 'react-helmet';
// import { sources } from '../mapui/data/sources';
// import { FiExternalLink } from 'react-icons/fi';

const useStyles = createUseStyles({
    main: {
        marginTop: 80,
        paddingLeft: 100,
        paddingRight: 100,
        maxWidth: 1000,
        marginLeft: 'auto',
        marginRight: 'auto',
        minHeight: 'calc(100vh - 250px)',
        '@media (max-width: 768px)': {
            paddingLeft: 20,
            paddingRight: 20,
        },
    },
});

export function Elections() {
    const styles = useStyles();
    const [states, setStates] = useState<any[]>([]);
    const [selectedState, setSelectedState] = useState<any>(null);
    const [stateData, setStateData] = useState<any>(null); // Define stateData and its setter here
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useMediaQuery("(max-width: 50em)");

    useEffect(() => {
        setStates(statesData);
        const queryParams = new URLSearchParams(location.search);
        const stateFromURL = queryParams.get('state');
        if (stateFromURL) {
            const stateObj = statesData.find((state) => state.name === stateFromURL);
            setSelectedState(stateObj);
        }
    }, [location]);

    useEffect(() => {
        if (selectedState !== null && selectedState.code !== "ALL") {
            import(`../mapui/data/${selectedState.code}properties.json`)
                .then(data => {
                    setStateData(data.default); // Assuming the imported module uses default export
                })
                .catch(error => {
                    console.error("Failed to load state data", error);
                    setStateData(null);
                });
            navigate(`?state=${selectedState.name}`);
        } else {
            setStateData(null);
            navigate(location.pathname);
        }
    }, [selectedState, navigate, location.pathname]);

    const handleStateChange = (value: any) => {
        const stateObj = states.find((state) => state.name === value);
        setSelectedState(stateObj);
    };

    // function getSource(
    //     electionKey: string,
    //     value: any
    // ): React.ReactNode {
    //     const source = value.source;
    //     const sourceLink: string | null = sources[source] ? sources[source].link : "";
    //     const sourceName = sources[source] ? sources[source].shortName : "";

    //     if (sourceLink) {
    //         return (
    //             <a 
    //                 style={{ color: 'rgb(99, 99, 255)', textDecoration: 'none', display: 'flex', alignItems: 'center' }} 
    //                 href={sourceLink} 
    //                 target="_blank" 
    //                 rel="noopener noreferrer"
    //             >
    //                 {sourceName}&nbsp;
    //                 <FiExternalLink />
    //             </a>
    //         );
    //     }

    //     return <span>{source}</span>;
    // }

    return (
        <div className={styles.main}>
            <Helmet>
                <title>Elections</title>
                <meta name="description" content="All of the elections added to Redistricter" />
            </Helmet>
            <h1 style={{ fontSize: 32, textAlign: 'center' }}>Election data library by state</h1>
            <Select
                placeholder="State"
                searchable={!isMobile}
                nothingFound="No state found"
                maxDropdownHeight={400}
                style={{ maxWidth: 200, marginBottom: 20, marginLeft: 'auto', marginRight: 'auto' }}
                // searchable
                data={states.map((state) => ({
                    value: state.name,
                    label: state.name,
                    group: (state.name === "Puerto Rico" || state.name === "Washington DC") ? "Territories" : "States"
                }))}
                onChange={handleStateChange}
                dropdownComponent="div"
                value={selectedState ? selectedState.name : "All states"}
                // defaultValue="All states"
                // size='md'
            />
            {stateData ? (
                <>
                    <h3>
                        {Object.keys(stateData).length - 1} elections available
                    </h3>
                    <Table>
                        <thead>
                            <tr>
                                <th>Year</th>
                                <th>Election</th>
                                {/* <th>Source</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>2028 Guess*</td>
                                <td>President</td>
                                {/* <td>Redistricter**</td> */}
                            </tr>
                            <tr>
                                <td>2024 Guess*</td>
                                <td>President</td>
                                {/* <td>Redistricter**</td> */}
                            </tr>
                            {Object.entries(stateData).map(([key, value]) => {
                                if (
                                    key.startsWith("G") &&
                                    key !== "GShift" &&
                                    key !== "GTurnout" &&
                                    key !== "GTurnoutShift" &&
                                    !key.startsWith("GP") &&
                                    key !== "GGuess"
                                ) {
                                    // @ts-ignore
                                    const displayName = value.bigDisplayName ? value.bigDisplayName : value.displayName;
                                    const [year, ...officeParts] = displayName.split(' ');
                                    const election = officeParts.join(' ');

                                    return (
                                        <tr key={key}>
                                            <td>{year}</td>
                                            <td>{election}</td>
                                            {/* <td>{getSource(key, value)}</td> */}
                                        </tr>
                                    );
                                }
                                return null;
                            })}
                        </tbody>
                    </Table>
                </>
            ) : (
                <>
                    <h3>{(allElections.total).toLocaleString()} elections across every state and Puerto Rico</h3>

                    <Table>
                        <thead>
                            <tr>
                                <th>Year</th>
                                <th>Office</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>2028 Guess*</td>
                                <td>President</td>
                            </tr>
                            <tr>
                                <td>2024 Guess*</td>
                                <td>President</td>
                            </tr>
                            <tr>
                                <td>2020</td>
                                <td>President</td>
                            </tr>
                            <tr>
                                <td>2016</td>
                                <td>President</td>
                            </tr>
                        </tbody>
                    </Table>
                </>
            )}
            <div
            style={{ marginBottom: 30, color: "rgb(70, 70, 70)", fontSize: isMobile ? 12 : 14 }}>
                <p>Redistricter also includes easily viewable turnout, turnout shift, and margin shift data for every election</p>
                <p>*Assumes the 2016-2020 president shift continues and the nationwide margin remains unchanged. Uses 2020 turnout.</p>
                <p>**Source data for calculations is the same as the source for the 2016 and 2020 presidential elections</p>
            </div>

            <p style={{ marginBottom: 50, fontSize: 18, fontWeight: 500 }}>Or view the list of demographic datasets <a style={{ color: 'rgb(99, 99, 255)', textDecoration: 'none' }} href="https://docs.redistricter.com/data/data-list/#demographic-datasets">here</a></p>
        </div>
        // <p className={styles.subtext}>
        //   All data is available at the precinct, county, block group, and census
        //   block level
        // </p>
        // <h2>Elections</h2>
        // <p>Shift (all elections)</p>
        // <p>Turnout (all elections)</p>
    );
}
