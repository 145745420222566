import React, { useState } from "react";
import styles from "./accountDropdown.module.css";
import { FiUser } from "react-icons/fi";
import { getFirebase } from "../lib/firebase";
// import useAppSettings from "../hooks/useAppSettings";

export function AccountDropdown({ mapHeaderCSS }) {
  const [open, setOpen] = useState(false);
  const { auth } = getFirebase();

  // const { getStripeRole, account } = useAppSettings();
  // const stripeRole = getStripeRole();

  const toggleDropdown = () => {
    setOpen(!open);
  };

  return (
    <div
      className={styles.container}
      style={{
        ...(!mapHeaderCSS ? {
          marginTop: "4px"
        } : {zIndex: 100})
      }}
    >
      <FiUser
        className={styles.icon}
        onClick={toggleDropdown}
        style={{
          ...(mapHeaderCSS && {
            padding: "7.5px",
            width: "40px",
            height: "40px"
          })
        }}
      />
      {open && (
        <div
          className={styles.dropdown}
          // style={{
          //   ...(bootstrapCSS && {
          //     marginTop: "10px",
          //     paddingTop: "10px"
          //   })
          // }}
        >
          <p className={styles.email}>{auth.currentUser.email}</p>
          {/* {!stripeRole ? (
            <p className={styles.subtext}>
              <a href="/signup">Inactive account</a>
            </p>
          ) : (
            <p className={styles.subtext}>
              {stripeRole} account
              {stripeRole === "Trial" && ` (${account.daysLeft} days left)`}
            </p>
          )} */}
          <button
            className={styles.button}
            onClick={() => window.open("/maps", "_blank")}
            style={{ marginTop: "10px" }}
          >
            Maps
          </button>
          {/* <button className={styles.button} onClick={() => window.open("/datahub", "_blank")}>DataHub</button> */}
          <button
            className={styles.button}
            onClick={() => window.open("/account", "_blank")}
          >
            Account Settings
          </button>
          <button className={styles.button} onClick={() => auth.signOut()}>
            Sign Out
          </button>
        </div>
      )}
    </div>
  );
}
