import React from "react";
import Header from "../components/Header";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Logos from "./components/Logos";
import SplittingAnimation from "./components/SplittingAnimation";
import { Datasets } from "./components/Datasets";
import Carousel from "./components/Carousel";
import Simulation from "./components/Simulation";
import Pricing from "./components/Pricing";
import Faq from "./components/Faq";
import { Helmet } from 'react-helmet';

export default function NewLandingPage() {
    return (
        <div className="apply-tailwind bg-neutral-50">
            <Helmet>
                <title>Redistricter</title>
                <meta name="description" content="The most powerful mapping tool for visualizing the data that shapes America" />
                <meta name="keywords" content="Data visualization, mapping, politics, redistricting" />
                <meta name="author" content="Colin Miller" />
                <meta name="twitter:site" content="@Redistricter" />
                <meta name="twitter:creator" content="@Redistricter" />
                <meta name="twitter:title" content="Redistricter" />
                <meta name="twitter:description" content="The most powerful mapping tool for visualizing the data that shapes America" />
                <meta name="twitter:image" content="https://redistricter.com/static/media/1.fbdd753f3a227828d0de.png" />
            </Helmet>
            <div className="max-w-7xl mx-auto">
                <Header />
                <div className="px-4">
                    <Hero />
                    <Logos />
                    <Datasets />
                    <SplittingAnimation />
                    <Carousel />
                    <Simulation />
                    <Pricing />
                    <Faq />
                </div>
            </div>
            <Footer />
        </div>
    );
}
